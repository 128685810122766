import React, { useContext } from "react";
// @ts-ignore
import Pluralize from "react-pluralize";
import { withCartIdParam } from "../../utils/BrowserHelper";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";
import { Cart } from "./types";
import { LightBoxViewCart } from "./vectors/LightBoxCartIcons";
import { EventGalleryContext } from "./index";

const EmbedHeader = ({
  cart,
  isEmbed,
  primaryColor
}: {
  cart: Cart;
  isEmbed: boolean;
  primaryColor: string;
}) => {
  const context = useContext(EventGalleryContext);

  return (
    <div className="dv-embed-header">
      <a href="https://geosnapshot.com" target="_blank">
        <div className="embed-branding"></div>
      </a>

      <div className="embed-checkout">
        {cart?.photos?.length ? (
          <span className="cart-photos">
            <Pluralize singular="photo" count={cart.photos.length} />
          </span>
        ) : null}
        <a
          href={withCartIdParam(
            "/checkout?source=embed-nav-bar",
            cart ? cart.id : null,
            isEmbed
          )}
          target={isEmbed ? "_blank" : ""}
          className="btn btn-primary checkout"
        >
          <LightBoxViewCart
            color={ContrastForegroundUtility.fgColor(primaryColor)}
          />{" "}
          View Cart
          {cart?.photos?.length ? (
            <span className="photos">&nbsp;({cart.photos.length})</span>
          ) : null}
        </a>
      </div>
    </div>
  );
};

export default EmbedHeader;

import React from "react";

const EmailIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6808 11.6283H0.820158C0.369071 11.6283 0 11.2593 0 10.8082V1.19183C0 0.740745 0.369071 0.371674 0.820158 0.371674H14.6808C15.1319 0.371674 15.501 0.740745 15.501 1.19183V10.8082C15.501 11.2593 15.1319 11.6283 14.6808 11.6283ZM1.64032 9.98801H13.8607V1.99147H1.64032V9.98801Z"
        fill={color}
      />
      <path
        d="M7.75023 7.71208C7.5657 7.71208 7.38116 7.65056 7.23763 7.52754L0.307301 1.82746C-0.0412657 1.5404 -0.0822736 1.02781 0.204782 0.679242C0.491837 0.330675 1.00443 0.289667 1.353 0.576722L7.77074 5.84623L14.1885 0.576722C14.537 0.289667 15.0496 0.330675 15.3367 0.679242C15.6238 1.02781 15.5827 1.5404 15.2342 1.82746L8.30384 7.52754C8.11931 7.65056 7.93477 7.71208 7.75023 7.71208Z"
        fill={color}
      />
    </svg>
  );
};
export default EmailIcon;

import React, { KeyboardEvent, useState } from "react";
import * as EmailValidator from "email-validator";

type SaveCartProps = {
  closeSaveCartModal: () => void;
  cartId: number | null;
  setSaveCartPromptShown: () => void;
  setGuestUser: (userId: number) => void;
  trackCartEmailPopupActions: (eventName: string) => void;
};

const SaveCart = ({
  closeSaveCartModal,
  cartId,
  setSaveCartPromptShown,
  setGuestUser,
  trackCartEmailPopupActions
}: SaveCartProps) => {
  const customerEmailField: any = React.createRef();
  const [shouldDisableSave, setShouldDisableSave] = useState(false);

  const saveGuestUserCart = () => {
    const email = customerEmailField.current.value;
    const requestBody = {
      cart_id: cartId,
      email: email.toLowerCase()
    };

    if (EmailValidator.validate(email)) {
      setShouldDisableSave(true);
      fetch("/api/v1/customer_user/create_guest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          trackCartEmailPopupActions("event-gallery:cart-email-provided");
          setGuestUser(data.user_id);
          //@ts-ignore
          toastr.success("Email sent with a link to your cart", null, {
            timeOut: 1000
          });
          setSaveCartPromptShown();
          setShouldDisableSave(false);
          closeSaveCartModal();
        })
        .catch(error => {
          const errorMessage = error.message.replace("Error: ", "");
          //@ts-ignore
          toastr.error(errorMessage);
        });
    }
    // @ts-ignore
    else toastr.error("Please enter a valid email");
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveGuestUserCart();
    }
  };
  const handleBackdropClick = () => {
    setSaveCartPromptShown();
    trackCartEmailPopupActions("event-gallery:cart-email-popup-dismissed");
    closeSaveCartModal();
  };

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal geo-modal--small fade in  geo-modal geo-multi-modal eg-save-cart-modal"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title eg-scp-header">
                Photo added to cart
              </span>
              <button
                type="button"
                className="close confirm-close"
                onClick={() => {
                  trackCartEmailPopupActions(
                    "event-gallery:cart-email-popup-dismissed"
                  );
                  setSaveCartPromptShown();
                  closeSaveCartModal();
                }}
              >
                <span>&times;</span>
              </button>{" "}
            </div>

            <div className="modal-body">
              <p className="eg-scp-pt">Add your email to save your cart</p>
              <input
                type="email"
                placeholder="Enter your email"
                ref={customerEmailField}
                className="geo-round-input geo-form__pbg eg-scp-customer-email"
                onKeyDown={handleKeyDown}
                autoFocus={true}
              />
              <p className="eg-scp-st">
                By adding your email address you consent to use as per the
                website {" "}
                <a
                  className="eg-scp-terms-link eg-scp-terms-clr"
                  href="https://geosnapshot.com/terms_and_conditions"
                  target="_blank"
                >
                  Terms & conditions
                </a>
              </p>

              <div className="geo-modal__actions eg-scp-email-footer">
                <button
                  className="geo-btn geo-btn--primary geo-btn--caps eg-save-cart-btn"
                  onClick={saveGuestUserCart}
                  disabled={shouldDisableSave}
                >
                  {" "}
                  Save{" "}
                </button>
              </div>
              <div
                className="eg-save-cart-not-now"
                onClick={() => {
                  setSaveCartPromptShown();
                  closeSaveCartModal();
                  trackCartEmailPopupActions(
                    "event-gallery:cart-email-popup-dismissed"
                  );
                }}
              >
                Not now
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-backdrop fade in"
        onClick={() => handleBackdropClick()}
      />
    </div>
  );
};

export default SaveCart;

import React, { useContext } from "react";
import "./styles/albums-list.css";
import { EventGalleryContext } from "./index";
const deleteIcon = require("./styles/delete.svg");

// @ts-ignore
import HideCancel from "./vectors/HideCancel";
import HideIcon from "./vectors/HideIcon";

const CurationMode = ({
  activeFilters,
  curationMode,
  selectAll,
  selectAllVisible,
  listOfMediaId,
  setBulkTagging,
  confirmDelete,
  curatePhotos,
  canDeletePhotos,
  pageConfiguration,
  hideOrUnhideAction
}: {
  activeFilters: Function;
  curationMode: boolean;
  selectAll: boolean;
  selectAllVisible: Function;
  listOfMediaId: Array<object>;
  setBulkTagging: Function;
  curatePhotos: Function;
  confirmDelete: Function;
  canDeletePhotos: boolean;
  pageConfiguration: any;
  hideOrUnhideAction: Function;
}) => {
  const context = useContext(EventGalleryContext);
  return (
    <div className="geo-egm__t-banner">
      {curationMode && activeFilters().length == 0 ? (
        <div style={{ fontSize: 12 }}>
          {listOfMediaId.length} {listOfMediaId.length > 1 ? "photos" : "photo"}{" "}
          selected
        </div>
      ) : (
        curationMode &&
        activeFilters().length > 0 && (
          <span className=" check-radio checkmark">
            <label
              htmlFor="select-all-vis-egm"
              className="geo-tag-b__selectall"
            >
              <input
                id="select-all-vis-egm"
                type="checkbox"
                checked={selectAll}
                onChange={() => selectAllVisible()}
              />
              <span className="r-dot"></span>
              <span className="r-desc">Select all visible</span>
            </label>
          </span>
        )
      )}
      {curationMode && (
        <div className="curation-actions">
          <>
            {canDeletePhotos && (
              <button
                className="geo-icon-btn-styling geo-icon-round-border"
                onClick={() => confirmDelete()}
                disabled={listOfMediaId.length > 0 ? false : true}
                style={{
                  marginRight: 4
                }}
              >
                <img src={deleteIcon} style={{ width: 15 }} />
              </button>
            )}
            <button
              className="geo-icon-btn-styling"
              onClick={() => curatePhotos()}
              style={{ marginRight: 4 }}
              disabled={listOfMediaId.length > 0 ? false : true}
            >
              {hideOrUnhideAction() == "hide" ? (
                <HideCancel color={pageConfiguration.primaryColor} width={35} />
              ) : (
                <HideIcon color={pageConfiguration.primaryColor} width={35} />
              )}
            </button>
          </>
          <button
            className="geo-btn geo-btn--secondary-ghost geo-btn--caps geo-btn--small"
            onClick={() => setBulkTagging()}
            disabled={listOfMediaId.length > 0 ? false : true}
            style={{ color: pageConfiguration.primaryColor }}
          >
            + Tags
          </button>
        </div>
      )}
    </div>
  );
};
export default CurationMode;

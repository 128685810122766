import React, { useContext, Fragment } from "react";
import { removeParam } from "../../../utils/BrowserHelper";
const warnIcon = require("../styles/icon-red-warn.svg");
import { EventGalleryContext } from "../index";

interface PromptClearCartProps {
  clearCart: () => void;
  photoBundleDiscountAvailed: boolean;
  setClearCartWarning: (val: boolean) => void;
}

const PromptClearCart = ({
  clearCart,
  photoBundleDiscountAvailed,
  setClearCartWarning
}: PromptClearCartProps) => {
  const removePopupParam = async () => {
    setClearCartWarning(false);
  };

  const context = useContext(EventGalleryContext);

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in ns ns-modal geo-modal geo-modal--small geo-modal__remove-offer"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-header">
            &nbsp;
            <button
              type="button"
              className="close"
              onClick={() => {
                removePopupParam();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="geo-modal__header geo-modal--warning-bg">
            <img
              className="geo-modal__warn-icon"
              src={warnIcon}
              alt="exclamation-warn"
            />
            <h4>Cart will be Cleared</h4>
          </div>
          <div className="geo-modal__body">
            {photoBundleDiscountAvailed ? (
              <p>
                You can only apply this offer to one selfie and event at a time.
                If you change this selfie or apply another offer your current
                cart will be cleared.
              </p>
            ) : (
              <p>
                If you want to apply the photo bundle offer, all the photos from
                the cart will get cleared.
              </p>
            )}
          </div>
          <div className="modal-footer geo-modal__actions dont-break">
            <a
              className="geo-btn geo-btn--link  geo-btn--caps"
              style={{
                backgroundColor: "#C1C1C1",
                color: "white",
                marginRight: 10
              }}
              onClick={() => {
                removePopupParam();
              }}
            >
              Don't Apply
            </a>
            <a
              className="geo-btn geo-btn--secondary geo-btn--caps"
              onClick={async () => {
                await clearCart();
                removePopupParam();
              }}
            >
              Apply
            </a>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default PromptClearCart;

import React, { useEffect, useRef, useState, useContext } from "react";
// @ts-ignore
import videojs from "@mux/videojs-kit";
const hiddenIcon = require("./styles/imgHide.svg");
// TODO extract to separate file, or load from CDN
import "@mux/videojs-kit/dist/index.css";
import Favorite from "../BrowseEvent/components/albums/Favorite";
import { EventGalleryContext } from "./index";
import LightBoxNavBtns from "./LightBoxNavBtns";

interface LightBoxVideoProps {
  video: {
    videoContent: boolean;
    videoMuxPlaybackId: string;
    videoStartTime: string;
    hidden: boolean;
    id: number;
  };
  onClickFavoriteIcon: () => void;
  hidden?: boolean;
  liked: boolean;
  showFavIcon?: boolean;
  hasPrevImage: boolean;
  hasNextImage: boolean;
  resetLightBoxImage: (index: number) => void;
  photoIndex: number;
}

const LightBoxVideo: React.FC<LightBoxVideoProps> = ({
  video,
  onClickFavoriteIcon,
  hidden,
  liked,
  showFavIcon,
  hasPrevImage,
  hasNextImage,
  resetLightBoxImage,
  photoIndex
}) => {
  const videoPlayerRef = useRef<unknown>(null);
  const [videoId, setVideoId] = useState<string>(video.videoMuxPlaybackId);
  const eventGalleryContext = useContext(EventGalleryContext);

  useEffect(() => {
    if (videoPlayerRef.current) {
      videojs(videoPlayerRef.current).currentTime(
        parseInt(video.videoStartTime)
      );
      videojs(videoPlayerRef.current).play();
    }
  }, []);

  useEffect(() => {
    if (video.videoMuxPlaybackId !== videoId) {
      setVideoId(video.videoMuxPlaybackId);
    }
  }, [video.videoMuxPlaybackId]);

  return (
    <div className="dv-imgdetails" style={{ height: "100%", width: "100%" }}>
      {hidden && (
        <span className="geo-visibility-stamp">
          <img src={hiddenIcon} alt="Hidden Icon" />
        </span>
      )}
      <video
        id="my-player"
        className="video-js vjs-16-9"
        controls
        //@ts-ignore
        ref={videoPlayerRef}
        preload="auto"
        width="100%"
        data-setup="{}"
        muted
        disablePictureInPicture
      >
        <source src={videoId} type="video/mux" />
      </video>
      {showFavIcon && (
        <Favorite
          onClickFavoriteIcon={onClickFavoriteIcon}
          isFavorite={liked}
          id={video.id}
        />
      )}
      <LightBoxNavBtns
        hasPrevImage={hasPrevImage}
        hasNextImage={hasNextImage}
        resetLightBoxImage={resetLightBoxImage}
        photoIndex={photoIndex}
      />
    </div>
  );
};

export default LightBoxVideo;

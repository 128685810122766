import React, { Component } from "react";
import "./styles/style.css";
import { SocialSharingButtons } from "../../utils/SocialSharingButtons";
const closeIcon = require("./styles/remove.svg");
import ShareQRCode from "../ShareQRCode/index";
import "../ShareSocialPopup/styles/style.css";
interface ShareSocialPopupProps {
  galleryURL: string;
  hideShareGalleryModal?: Function;
  text?: string;
}

class ShareSocialPopup extends Component<ShareSocialPopupProps> {
  handleClose = () => {
    var sharePopupContainer = document.querySelector(".share-popup-container");
    //@ts-ignore
    sharePopupContainer.style.display = "none";
  };

  render() {
    return (
      <div>
        <div
          id="share-gallery-modal-dialog"
          className="modal fade in geo-modal geo-modal--small geo-modal--show geo-modal--scroll"
          role="dialog"
        >
          <div className="modal-dialog share-gallery-modal-dialog-content">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  onClick={() =>
                    this.props.hideShareGalleryModal
                      ? this.props.hideShareGalleryModal()
                      : this.handleClose()
                  }
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="share-gallery-modal-content-div">
                <p className="share-gallery-msg">
                  {this.props.text
                    ? this.props.text
                    : "Share the entire event gallery with participants"}
                </p>
                <p className="share-gallery-url">
                  <span>{this.props.galleryURL}</span>
                </p>
                <div className="social-gallery-links">
                  <a
                    className="ghost-btn copy-link-in-modal"
                    data-url={this.props.galleryURL}
                    onClick={() =>
                      navigator.clipboard.writeText(`${this.props.galleryURL}`)
                    }
                  >
                    <span className="text">Copy link</span>
                  </a>
                  <SocialSharingButtons
                    message="Check out the event photos"
                    sharingURL={this.props.galleryURL}
                  />
                </div>
                <div style={{ margin: "10px 0px" }}>
                  <ShareQRCode galleryPage={this.props.galleryURL} />
                </div>
                <div
                  className="share-gallery-modal-done-btn"
                  onClick={() =>
                    this.props.hideShareGalleryModal
                      ? this.props.hideShareGalleryModal()
                      : this.handleClose()
                  }
                >
                  {" "}
                  Done
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="modal-backdrop fade in" />
      </div>
    );
  }
}

export default ShareSocialPopup;

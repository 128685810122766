import React, { useContext, Fragment, useState } from "react";
import { EventGalleryContext } from "./index";
import { Event } from "./types";
import LockIcon from "./vectors/LockIcon";
import ajax from "superagent";

const PasswordProtected = ({
  event,
  unlockEvent,
  updateUserInputPassword
}: {
  event: Event;
  unlockEvent: () => void;
  updateUserInputPassword: (password: string | any) => void;
}) => {
  const context = useContext(EventGalleryContext);
  const passwordField: any = React.createRef();
  const [loader, setLoader] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const passwordFormSubmit = (e: any) => {
    e.preventDefault();
    setLoader(true);
    authenticatePassword(e);
  };

  const authenticatePassword = (e: any) => {
    e.preventDefault();
    let password = passwordField.current.value;
    ajax
      .get(context.authenticateEventPasswordAPI)
      .query({
        event_password: password
      })
      .then(res => {
        if (res.body.authenticated) {
          updateUserInputPassword(password);
          unlockEvent();
          updateInvalidPassword(false);
        } else {
          updateInvalidPassword(true);
        }
      });
  };

  const updateInvalidPassword = (invalid: boolean) => {
    setInvalidPassword(invalid);
    setLoader(false);
  };

  const mailtoEventOrganiser = () => {
    return (
      `mailto:${event.organiserEmail}?cc=support@geosnapshot.com&subject=Password request for ${event.name}&body=` +
      encodeURIComponent(
        `Hi ${event.organiserFirstName}, I'm requesting the password for the event ${event.name} could you please reply to this email with the password so I can view and purchase the photos.`
      )
    );
  };

  return (
    <div className="egm-nopics egm-noresults">
      <div className="egm-password">
        <LockIcon color="#30053F" />
        <h5 className="egm-nopics-title">This event is protected</h5>
        <p className="egm-nopics-para">
          Please enter the password provided by the Event Organiser
        </p>
        <form onSubmit={passwordFormSubmit}>
          <div
            className={
              "get-notified-input-div" + (invalidPassword ? " has-error" : "")
            }
          >
            <input
              className="form-control"
              ref={passwordField}
              placeholder="Event Password"
              // @ts-ignore
              defaultValue={
                context.currentUserHasPasswordAccess
                  ? context.eventPassword
                  : ""
              }
            />
            {invalidPassword ? (
              <span className="error egm-nopics-para">
                The password you've given is incorrect.
              </span>
            ) : null}
            <button
              className="get-notified-btn"
              onClick={passwordFormSubmit}
              disabled={loader}
            >
              {!loader ? (
                <span>Unlock</span>
              ) : (
                <>
                  <span>Please wait </span>
                  <span className="fa fa-circle-o-notch fa-spin"></span>
                </>
              )}
            </button>
          </div>
          <br /> <br />
          <p className="egm-nopics-para">
            Contact the{" "}
            <a
              style={{
                color: context.pageConfiguration
                  ? context.pageConfiguration.primaryColor
                  : "#FF0072"
              }}
              href={mailtoEventOrganiser()}
            >
              Event Organiser
            </a>{" "}
            to get the password
          </p>
        </form>
      </div>
    </div>
  );
};
export default PasswordProtected;

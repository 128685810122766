import React, { Component } from "react";
import MultiSelectInput from "../../../AlbumTag/components/MultiSelectInput";
import "../../../AlbumTag/styles/style.css";

interface BundleMediaSelectProps {
  mediaCount: number;
  appliedTags: Array<{ name: string; tagId: number }>;
  contentTagsAPI: string;
  handleClose: Function;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  handlePostCall: Function;
}

class BundleMediaSelect extends Component<BundleMediaSelectProps> {
  photoId = () => this.props.listOfMediaId.map(val => val.id);
  render() {
    return (
      <div>
        <div
          id="album-tag-modal-dialog"
          className="modal fade in geo-modal geo-modal--small geo-modal--scroll"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add tags to photos</h5>
                <button
                  className="close"
                  onClick={() => this.props.handleClose()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="album-name-div">
                  <span className="album-modal-sub sub-2-font">
                    {this.props.mediaCount}{" "}
                    {this.props.mediaCount === 1
                      ? "photo selected"
                      : "photos selected"}
                  </span>
                </div>
                <MultiSelectInput
                  appliedTags={this.props.appliedTags}
                  contentTagsAPI={this.props.contentTagsAPI}
                  handleClose={this.props.handleClose}
                  tagType={"photo"}
                  photoId={this.photoId()}
                  handlePostCall={this.props.handlePostCall}
                  parentName={"event-gallery-curate"}
                />
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="modal-backdrop fade in" />
      </div>
    );
  }
}

export default BundleMediaSelect;

import React, { Component } from "react";
import QRCodeCreator from "../../utils/QRCodeCreator";

interface ShareQRCodeProps {
  galleryPage: string;
}

class ShareQRCode extends Component<ShareQRCodeProps> {
  qrcodeRefs: React.RefObject<HTMLDivElement>;
  constructor(props: ShareQRCodeProps) {
    super(props);
    this.qrcodeRefs = React.createRef();
  }

  downloadQRCode = (qrRef: any) => {
    const svg = qrRef.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      //@ts-ignore
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px"
        }}
      >
        <div ref={this.qrcodeRefs}>
          <QRCodeCreator linkURL={this.props.galleryPage} />
        </div>
        <button
          type="button"
          onClick={() => this.downloadQRCode(this.qrcodeRefs)}
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#ff0072",
            fontSize: "12px",
            fontWeight: "bold",
            letterSpacing: "0.1em"
          }}
        >
          DOWNLOAD QR CODE
        </button>
      </div>
    );
  }
}
export default ShareQRCode;

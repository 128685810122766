import React from "react";
import _ from "lodash";
import { Photo, Cart } from "../types";
import Modal from "../../../../utils/Modal";
import LightBox from "../../../BrowseEvent/components/albums/LightBox";
import Gallery from "react-grid-gallery";
import millisecondsTohms from "../../../../utils/millisecondsTohms";
const playIcon = require("../../styles/play.svg");
import {
  CartOutline,
  CartFilled
} from "../../../BrowseEvent/components/vectors/AddToCartIcon";
import "../../styles/styles.css";

interface PhotoGalleryProps {
  photos: Photo[];
  addPhotoToCart: (photo: Photo) => void;
  removePhotoFromCart: (photo: Photo) => void;
  cart: Cart;
  isEmbed: boolean;
  loadMorePhotos: () => void;
  isAuthorizedForDirectDownloads: boolean;
  primaryColor?: string;
  userId?: number;
  parentName?: string;
}
interface PhotoGalleryState {
  showLightBox: boolean;
  photoIndex: number;
  photos: Photo[];
}
const tagStyle = {
  display: "inline",
  padding: ".2em .6em .3em",
  fontSize: "60%",
  fontWeight: "600",
  lineHeight: "1",
  color: "#FFF",
  background: "rgba(7,36,22,.6)",
  textAlign: "center",
  whiteSpace: "nowrap",
  verticalAlign: "baseline",
  borderRadius: ".25em"
};

const customThumbViewportStyle = () => {
  return {
    overflow: "hidden"
  };
};

const customThumbStyle = () => {
  return {
    cursor: "pointer",
    marginTop: 0,
    WebkitTouchCallout: "none"
  };
};

interface ImageComponentProps {
  props: {
    item: {
      videoContent: any;
      videoDuration: string | number;
      thumbnail: string | undefined;
      id: number;
      album: { name: string };
      hidden: boolean;
      favorite: boolean;
      isSelected: boolean;
    };
    imageProps: JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLImageElement> &
      React.ImgHTMLAttributes<HTMLImageElement>;
  };
  handleImageSelect: ({ photo }: { photo: any }) => void;
  primaryColor: string;
}

const ImageComponent = ({
  props,
  handleImageSelect,
  primaryColor
}: ImageComponentProps) => {
  const [show, setShow] = React.useState(false);

  const ImageSelectIcon = (
    <div
      className="geo-cart-icon-container"
      onClick={e => {
        e.stopPropagation();
        handleImageSelect({ photo: props.item });
      }}
    >
      {props.item.isSelected ? (
        <CartFilled color={primaryColor} />
      ) : (
        <CartOutline />
      )}
    </div>
  );
  if (props.item.videoContent) {
    return (
      <div style={{ textAlign: "center", cursor: "pointer" }}>
        <span className="time-stamp">
          {millisecondsTohms(props.item.videoDuration)}
        </span>
        <img className="play-icon" src={playIcon} />
        {ImageSelectIcon}
        <img
          height="120px"
          src={props.item.thumbnail}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          alt={`${props.item.album.name}-${props.item.id}`}
        />
      </div>
    );
  } else
    return (
      <>
        {ImageSelectIcon}
        <img
          {...props.imageProps}
          alt={`${props.item.album.name}-${props.item.id}`}
        />
      </>
    );
};

class PhotoGallery extends React.Component<
  PhotoGalleryProps,
  PhotoGalleryState
> {
  constructor(props: PhotoGalleryProps) {
    super(props);

    this.state = {
      showLightBox: false,
      photoIndex: -1,
      photos: []
    };
  }

  public componentDidMount() {
    this.updatePhotos(
      this.props.photos,
      this.props.cart ? this.props.cart.photos : []
    );
  }

  public componentWillReceiveProps(nextProps: PhotoGalleryProps) {
    this.updatePhotos(
      nextProps.photos,
      nextProps.cart ? nextProps.cart.photos : []
    );
  }

  handleImageSelect = ({ photo }: { photo: any }) => {
    this.updatePhotoInCart(photo);
  };

  public render() {
    return (
      <>
        <Gallery
          images={this.state.photos}
          rowHeight={120}
          margin={5}
          enableLightbox={false}
          enableImageSelection={false}
          thumbnailImageComponent={(props: any) => {
            return (
              <ImageComponent
                props={props}
                handleImageSelect={this.handleImageSelect}
                primaryColor={this.props.primaryColor || "#34b2a7"}
              />
            );
          }}
          onClickThumbnail={this.showLightBox}
          tagStyle={tagStyle}
          selectedIconColor={this.props.primaryColor || "#34b2a7"}
          enableKeyboardInput={true}
          onContextMenu={this.contextMenu}
          tileViewportStyle={customThumbViewportStyle}
          thumbnailStyle={customThumbStyle}
          onRender={() => null}
        />
        {this.state.showLightBox ? (
          <Modal>
            <LightBox
              //@ts-ignore
              event={this.photoEvent()}
              eventClickable={true}
              //@ts-ignore
              album={this.photoAlbum()}
              photos={this.state.photos}
              photoIndex={this.state.photoIndex}
              closeLightBox={this.closeLightBox}
              showLightBox={this.showLightBox}
              cartHasPhotos={
                this.props.cart && this.props.cart.photos.length > 0
              }
              addToCart={this.props.addPhotoToCart}
              removeFromCart={this.props.removePhotoFromCart}
              cartId={this.props.cart ? this.props.cart.id : null}
              isEmbed={this.props.isEmbed}
              isAuthorizedForDirectDownloads={
                this.props.isAuthorizedForDirectDownloads
              }
              primaryColor={this.props.primaryColor || "#259fa5"}
              userId={this.props.userId}
              parentName={this.props.parentName}
            />
          </Modal>
        ) : null}
      </>
    );
  }

  private updatePhotos = (photos: Photo[], photosInCart: number[]) => {
    this.setState({
      photos: photos.map((photo: Photo) => {
        return {
          ...photo,
          ...(photo.timeTaken
            ? {
                tags: [
                  {
                    title: "Time",
                    value: photo.timeTaken
                  }
                ]
              }
            : {}),
          isSelected: _.includes(photosInCart, photo.id),
          src: "",
          disableContextMenu: true
        };
      })
    });
  };
  private contextMenu = (e: any) => {
    e.preventDefault();
    return false;
  };
  private photoEvent = () =>
    this.state.photos[this.state.photoIndex].album.event;
  private photoAlbum = () => this.state.photos[this.state.photoIndex].album;

  private updatePhotoInCart = (photo: Photo) => {
    if (photo.isSelected) {
      this.props.removePhotoFromCart(photo);
    } else {
      this.props.addPhotoToCart(photo);
    }
  };
  private showLightBox = (index: number) => {
    this.setState({ showLightBox: false }, () => {
      this.setState({
        photoIndex: index,
        showLightBox: true
      });
    });

    if (this.state.photos[index + 1] === undefined) {
      this.props.loadMorePhotos();
    }
  };

  private closeLightBox = () => {
    this.setState({
      showLightBox: false
    });
  };
}

export default PhotoGallery;

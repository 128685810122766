import React, { useState, useEffect, FC } from "react";
import getAlbumId from "../../utils/GetAlbumId";
import { fetchAlbumDetails } from "../PhotoManager/API/api";
import "./styles.css";
const eventFile = require("../PhotoManager/styles/images/Event.svg");
const albumIcon = require("../PhotoManager/styles/images/blackFolder.svg");
const createAlbum = require("../PhotoManager/styles/images/createAlbum.svg");
const DownArrow = require("../PhotoManager/styles/images/DownArrow.svg");
const SideArrow = require("../PhotoManager/styles/images/SideArrow.svg");
const gssNewLoader = require("../../assets/gss-loader.gif");
import Telemetry from "../../utils/rudderstackAnalytics";

export interface EventType {
  id: number;
  name: string;
  currency: string;
  slug: string;
  startDate: string;
  endDate: string;
  organiserEmail: string;
  organiserFirstName: string;
  location: string;
  browsePhotosPath: string;
  price: number;
  displayPrice: string;
  date: string;
  isProtected: boolean;
  albumsCount: number;
  tags: string[];
}

export interface Album {
  id: number;
  name: string;
  slug: string;
  photosCount: number;
  eventId: number;
  eventName: string;
  signedId: string;
  photographerName: string;
  photographerEmail: string;
  photographerId: number;
  tags: string[];
  thumbnailURL: null | string;
  albumPath: string;
}

interface EventListProps {
  currentEventId: number;
  albumObject: any;
}

const EventList: FC<EventListProps> = ({ currentEventId, albumObject }) => {
  const [events, setEvents] = useState<EventType[]>([]);
  const [openedEvents, setOpenedEvents] = useState<Set<number>>(new Set());
  const [albums, setAlbums] = useState<Record<number, Album[]>>({});
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [albumLoading, setAlbumLoading] = useState<number | null>(null);
  const [nextPage, setNextPage] = useState<number | null>(1);
  const isMobile = window.innerWidth < 992;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (nextPage !== null) {
      fetchEventListFunction(nextPage);
    }
  }, []);

  const loadMore = () => {
    if (nextPage !== null) {
      fetchEventListFunction(nextPage);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sidebar = urlParams.get("sidebar");

    if (!isMobile && sidebar && getAlbumId()) {
      const eventId = getEventIdFromUrl();
      handleEventClick(eventId);
      Telemetry.track("content-library:event-opened", {
        source: "directory-view"
      });
    }
  }, [albumObject]);

  const fetchEventListFunction = async (page: number) => {
    setLoading(true);
    try {
      const response = await fetch(
        `/api/v1/photo-manager/contents?t=root&limited=true&page=${page}`
      );
      if (!response.ok) {
        throw new Error("Error fetching events");
      }
      const data = await response.json();
      const newEvents = data.events;
      const meta = data.meta;

      if (newEvents.length > 0) {
        setEvents(prevEvents => [...prevEvents, ...newEvents]);
      }

      setNextPage(meta.nextPage);
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEventClick = async (eventId: number) => {
    if (!openedEvents.has(eventId)) {
      setAlbumLoading(eventId);
      setOpenedEvents(prev => new Set(prev).add(eventId));
      try {
        const fetchedAlbums = await fetchAlbumDetails(eventId);
        setAlbums(prev => ({
          ...prev,
          [eventId]: fetchedAlbums
        }));
        setSelectedAlbum(null);
      } catch (error) {
        setOpenedEvents(prev => {
          const newSet = new Set(prev);
          newSet.delete(eventId);
          return newSet;
        });
        console.error("Error fetching albums:", error);
      } finally {
        setAlbumLoading(null);
      }
    } else {
      setOpenedEvents(prev => {
        const newSet = new Set(prev);
        newSet.delete(eventId);
        return newSet;
      });
    }
  };

  const handleAlbumClick = (album: Album, parentId: number) => {
    setSelectedAlbum(album);
    if (isMobile) jumpToContentView();
  };

  const jumpToContentView = () => {
    document.getElementById("content-view")?.scrollIntoView();
  };

  const handleToggleSidebar = () => {
    if (!isOpen) {
      Telemetry.track("content-library-sidebar-opened");
    }
    setIsOpen(!isOpen);
  };

  const getEventIdFromUrl = () => {
    const pathname = window.location.pathname;
    const pathSegments = pathname.split("/");
    const eventIdFromUrl = pathSegments[pathSegments.length - 1];
    return JSON.parse(eventIdFromUrl);
  };

  return (
    <div className={isMobile ? "" : "geo-gallery-sidebar"}>
      {isMobile && (
        <button className="toggle-sidebar-btn" onClick={handleToggleSidebar}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Jump to an event or album</span>
            <span className="sub-span">Content Library</span>
          </div>
          <img src={isOpen ? DownArrow : SideArrow} />
        </button>
      )}
      {!isMobile && (
        <div className="geo-manager-header">
          <span>Content Library</span>
        </div>
      )}

      <ul
        className={
          isMobile ? `content ${isOpen || !isMobile ? "active" : ""}` : ""
        }
        style={{
          display: isMobile
            ? isOpen || (isOpen && !isMobile)
              ? "block"
              : "none"
            : "block",
          marginTop: isMobile ? -2 : 10,
          paddingLeft: isMobile ? 15 : 0
        }}
      >
        {isMobile && <hr />}
        {loading && nextPage === 1 ? (
          <div className="gss-loader">
            <img src={gssNewLoader} />
          </div>
        ) : (
          events.map(event => (
            <div className="event-div" key={event.id}>
              <li
                className={`event-item ${
                  currentEventId == event.id ? "selected" : ""
                }`}
              >
                <div
                  className="arrow-spacing"
                  onClick={() => handleEventClick(event.id)}
                >
                  <img
                    src={openedEvents.has(event.id) ? DownArrow : SideArrow}
                  />
                </div>

                <span
                  className="event-group"
                  id={`event-name-tooltip-${event.id}`}
                  data-toggle="tooltip"
                  aria-hidden="true"
                >
                  <a
                    href={`${event.browsePhotosPath}?sidebar=open&source=content-library-sidebar`}
                  >
                    <img src={eventFile} className="file-spacing" />

                    <span className="event-name">{event.name}</span>
                  </a>
                </span>
              </li>
              {openedEvents.has(event.id) && (
                <div>
                  {albumLoading === event.id ? (
                    <div className="loading-spinner">
                      <span className="fa fa-spinner fa-spin"></span>
                    </div>
                  ) : (
                    <ul>
                      {albums[event.id]?.length > 0 ? (
                        albums[event.id].map(album => (
                          <li
                            className="album-border"
                            key={album.id}
                            onClick={() => {
                              handleAlbumClick(album, event.id);
                              Telemetry.track("content-library:album-opened", {
                                source: "directory-view"
                              });
                            }}
                          >
                            <div
                              className={`album-name-icon ${
                                (selectedAlbum &&
                                  selectedAlbum.id == album.id) ||
                                (albumObject && albumObject.id === album.id)
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <a
                                href={`${album.albumPath}&sidebar=open&source=content-library-sidebar`}
                              >
                                <img
                                  src={albumIcon}
                                  style={{ width: 16, marginRight: 5 }}
                                />{" "}
                                <span className="album-name">{album.name}</span>
                              </a>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className="album-border">
                          <a
                            className="create-album-btn"
                            href={`/dashboard/albums?album_event_id=${event.id}`}
                          >
                            <img
                              src={createAlbum}
                              style={{ width: 13, marginRight: 5 }}
                            />
                            <span>Create an album</span>
                          </a>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              )}
            </div>
          ))
        )}
        {nextPage && events.length > 0 && (
          <div className="load-more-container">
            <button
              className="geo-btn geo-btn--small geo-btn--secondary geo-btn--caps"
              onClick={() => loadMore()}
            >
              Load More
            </button>
          </div>
        )}
      </ul>
    </div>
  );
};

export default EventList;

import React, { useContext } from "react";
import { Album, Event } from "./types";
import "./styles/header.css";
import { EventGalleryContext } from "./index";
const shareIcon = require("./styles/share.svg");

const backIcon = require("./styles/back.svg");

const Header = ({
  event,
  albumName,
  navigateToPreviousURL,
  isExpanded = false,
  linkedEventsAvailable,
  promptLinkedEventsPopup,
  showShareGallery,
  albumModeID
}: {
  event: Event;
  albumName: string;
  navigateToPreviousURL: () => void;
  isExpanded: boolean;
  linkedEventsAvailable: boolean;
  promptLinkedEventsPopup: () => void;
  showShareGallery: Function;
  albumModeID: string | null;
}) => {
  const context = useContext(EventGalleryContext);
  const isNavigatedFromOrgGallery = localStorage.getItem(
    "isNavigatedFromOrgGallery"
  )
    ? JSON.parse(localStorage.getItem("isNavigatedFromOrgGallery") as string)
    : false;
    
  const canShowBackBtn = context.isEmbed ? isNavigatedFromOrgGallery : true;

  return (
    <div className="egm-head-wrapper">
      <div className={`egm-header-container egm-panel-div-purple`}>
        <div className="egm-header-div egm-header-div2 collapsed container">
          <div className="panel panel-default egm-panel-div">
            <div
              className="panel-heading egm-header-row"
              role="tab"
              id="headingOne"
            >
              {!albumModeID && canShowBackBtn ? (
                <div className="egm-header-btn-div">
                  <button onClick={() => navigateToPreviousURL()}>
                    <img src={backIcon} />
                  </button>
                </div>
              ) : null}
              <div>
                {albumModeID && (
                  <a id="event-link" href={event.browsePhotosPath}>
                    <p className="egm-header-name sub-heading">
                      <span className="fa fa-angle-left"> </span>{" "}
                      <span> {event.name}</span>{" "}
                    </p>
                  </a>
                )}
                <p className="egm-header-name egm-header-name2">
                  <span>{albumModeID ? albumName : event.name}</span>
                </p>
                <span>
                  <span className="egm-sub-details">{event.date + " "}</span>
                  <span className="egm-sub-details">
                    {"• " + event.location}
                  </span>
                  <span className="egm-sub-details">{event.country}</span>
                </span>
                {linkedEventsAvailable && (
                  <p
                    className="egm-header-linked"
                    onClick={() => promptLinkedEventsPopup()}
                  >
                    Linked Events
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;

import React from "react";
const warnIcon = require("../../EventGalleryMobile/styles/icon-red-warn.svg");

const DeletePhotosConfirmation = ({
  closePopup,
  deletePhotos
}: {
  closePopup: Function;
  deletePhotos: Function;
}) => {
  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in geo-modal geo-modal--small geo-modal__remove-offer"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-header">
            &nbsp;
            <button
              type="button"
              className="close"
              onClick={() => {
                closePopup();
              }}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="geo-modal__header geo-modal--warning-bg">
            <img
              className="geo-modal__warn-icon"
              src={warnIcon}
              alt="exclamation-warn"
            />
            <h4>Photos will be deleted </h4>
          </div>
          <div className="geo-modal__body">
            <p>Are you sure you want to delete all the selected photos?</p>
          </div>
          <div className="modal-footer geo-modal__actions dont-break">
            <a
              className="geo-btn geo-btn--link  geo-btn--caps"
              style={{ backgroundColor: "#C1C1C1", color: "white" }}
              onClick={() => {
                closePopup();
              }}
            >
              Cancel
            </a>
            <a
              className="geo-btn geo-btn--secondary geo-btn--caps"
              style={{ padding: "12px 30px", marginLeft: "15px" }}
              onClick={async () => {
                deletePhotos();
              }}
            >
              Delete
            </a>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default DeletePhotosConfirmation;

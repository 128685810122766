import React, { useState, useEffect, useContext } from "react";
import { Album, Photo } from "./types";
import ajax from "superagent";
import Photos from "./Photos";
import "./styles/albums-list.css";
import { EventGalleryContext } from "./index";
import { withCartIdParam } from "../../utils/BrowserHelper";
import _ from "lodash";

const leftArrIcon = require("./styles/left-arrow.svg");

const AlbumPhotos = ({
  album,
  toggleAlbum,
  eventPassword,
  likedMedia,
  handleLikeButtonClick,
  updateLikedMedia,
  isLikedMedia,
  guestSession
}: {
  album: Album;
  toggleAlbum: (album: Album | null) => void;
  eventPassword: string;
  likedMedia: Array<{ photoId: number; userId: number }>;
  handleLikeButtonClick: (
    photoId: number,
    userId: number,
    source: string
  ) => void;
  isLikedMedia: (photos: any) => void;
  updateLikedMedia: (val: any) => void;
  guestSession: boolean;
}) => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [nextPage, setNextPage] = useState<number | null>(1);
  const [totalCount, setTotalCount] = useState(0);
  const context = useContext(EventGalleryContext);

  useEffect(() => {
    if (context.reset == true) {
      setNextPage(1);
      setTotalCount(0);
    }
  }, [context.reset]);

  useEffect(() => {
    if (nextPage == 1 && context.guestUserId) {
      loadPhotos(true);
    } else if (nextPage == 1) {
      loadPhotos(false);
    }
  }, [nextPage]);

  useEffect(() => {
    setNextPage(1);
  }, [guestSession]);

  useEffect(() => {
    const updatedPhotos = galleryPhotos(photos);
    setPhotos(updatedPhotos);
    context.setPhotos(updatedPhotos);
  }, [likedMedia, context.listOfMediaId.length, context.cart?.photos.length]);

  const loadPhotos = async (reset: boolean = false) => {
    await photosRequest(
      album.links.photos,
      nextPage,
      context.cart ? context.cart.id : null,
      eventPassword
    )
      .then(response => {
        setPhotos(previousPhotos => {
          const photos = _.concat(
            context.reset || reset ? [] : previousPhotos,
            response.body.photos
          );
          updateLikedMedia(isLikedMedia(photos));
          return galleryPhotos(photos);
        });
        setNextPage(response.body.meta.nextPage);
        setTotalCount(response.body.meta.totalCount);
        context.reloadAlbumPhotos(false);
      })
      .catch(err => console.log(err));
  };

  const photosRequest = async (
    link: string,
    nextPage: number | null,
    cartId: number,
    eventPassword: string
  ) => {
    let req = ajax.get(withCartIdParam(link, cartId)).query({
      page: nextPage,
      user_id: context.userId || context.guestUserId
    });

    if (eventPassword !== "") {
      req = req.query({
        event_password: eventPassword
      });
    }

    return req;
  };

  const isSelected = (photo: Photo) => {
    return _.includes(
      context.curationMode
        ? context.listOfMediaId.map(item => item.id)
        : context.cart
        ? context.cart.photos
        : [],
      photo.id
    );
  };

  const isLiked = (photo: Photo) => {
    return likedMedia.some(like => like.photoId === photo.id);
  };

  const galleryPhotos = (photos: any) => {
    return photos.map((photo: any) => {
      return {
        ...photo,
        ...(photo.timeTaken
          ? {
              tags: [
                {
                  title: "Time",
                  value: photo.timeTaken
                }
              ]
            }
          : {}),
        isSelected: isSelected(photo),
        favorite: isLiked(photo),
        src: "",
        disableContextMenu: true
      };
    });
  };

  return (
    <div>
      <div className="egm-album-selected-div">
        <button
          className="egm-album-back-btn"
          onClick={() => toggleAlbum(null)}
        >
          <img src={leftArrIcon} />
        </button>
        <div>
          <p className="egm-album-selected-name">{album.name}</p>
          <p className="egm-album-count">
            <span className="egm-count">{album.photosCount} photos</span>{" "}
            {!context.hidePhotographersFilter && (
              <span>
                &#8226;&nbsp; by{" "}
                <a
                  href={`mailto:${album.photographerEmail}?cc=support@geosnapshot.com&subject=Customer contact about event: ${album.eventName}`}
                >
                  <span className="egm-pgrapher">{album.photographerName}</span>
                </a>
              </span>
            )}
          </p>
        </div>
      </div>
      {photos && (
        <Photos
          photos={photos}
          loadPhotos={loadPhotos}
          hasMore={!!nextPage}
          trackTelemetry={true}
          userId={context.userId ? context.userId : undefined}
          isAuthorizedForDirectDownloads={false}
          likedMedia={likedMedia}
          handleLikeButtonClick={handleLikeButtonClick}
          parentName={"event-gallery-lightbox"}
          showFavIcon={true}
        />
      )}
    </div>
  );
};
export default AlbumPhotos;

import React, { useContext } from "react";
import _ from "lodash";

const GroupByCountry = ({
  photosCount,
  selectedCountry,
  countries,
  updateCountry
}: {
  photosCount: number;
  selectedCountry: string;
  countries: string[];
  updateCountry: (country: string) => void;
}) => {
  return (
    <div className="photo-count text-left">
      <strong>{photosCount} photos</strong> matched in
      <div className="btn-group dd custom">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedCountry} <span className="fa fa-angle-down"></span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a onClick={() => updateCountry("Worldwide")}>Worldwide</a>
          </li>
          <li role="separator" className="divider"></li>
          {countries.map(country => (
            <li
              key={country}
              className={country === selectedCountry ? "active" : ""}
            >
              <a onClick={() => updateCountry(country)}>{country}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GroupByCountry;

import React, { useContext } from "react";
import "./styles/header.css";
import { EventGalleryContext } from "./index";
import CloseIcon from "./vectors/CloseIcon";
import UploadNotify from "./vectors/UploadNotify";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";

const GetNotifiedBanner = ({
  userEmail,
  photoBundleDiscountAvailed,
  registerCustomerLead,
  getNotifiedPopup,
  closeBanner
}: {
  userEmail: string | null;
  photoBundleDiscountAvailed: boolean;
  registerCustomerLead: (email: string) => void;
  getNotifiedPopup: () => void;
  closeBanner: () => void;
}) => {
  const context = useContext(EventGalleryContext);
  const iconColor = ContrastForegroundUtility.fgColor(
    ContrastForegroundUtility.lightenColor(
      context.pageConfiguration.primaryColor
    )
  );
  return (
    <div>
      <div className="egm-notify-div egm-ph-uploading-div">
        <div className="egm-color-notify-div">
          <div>
            <UploadNotify color={iconColor} />
          </div>
          <div className="egm-ph-uploading-info-div">
            <p className="egm-ph-uploading-info">
              Photos are still being uploaded
            </p>
          </div>
          <button
            className="egm-ph-uploading-btn"
            onClick={() =>
              userEmail ? registerCustomerLead(userEmail) : getNotifiedPopup()
            }
          >
            Notify me
          </button>
          <div className="egm-header-btn-div close-btn">
            <button onClick={() => closeBanner()}>
              <CloseIcon color={iconColor} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetNotifiedBanner;

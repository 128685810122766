import React, { Component } from "react";
import "./styles/style.css";
import PublishingOptionsPopup from "./PublishingOptionsPopup";
import PasswordWarningModal from "./PasswordWarningModal";
import { PublishOptionTypes } from "../EventsDashboard/EventsTable";

interface PublishingOptionsProps {
  closePopup?: Function;
  updateEventPrivacy?: Function;
  schoolUser: boolean | null;
  eventData: PublishOptionTypes;
}

interface PublishingOptionsState {
  publishOptionId: string;
  passwordInput: string;
  passwordToggle: boolean;
  switchPopup: boolean;
}

class PublishingOptions extends Component<
  PublishingOptionsProps,
  PublishingOptionsState
> {
  constructor(props: PublishingOptionsProps) {
    super(props);
    this.state = {
      publishOptionId: props.eventData.publishMode,
      passwordInput: props.eventData.password,
      passwordToggle: props.eventData.password ? true : false,
      switchPopup: false
    };
  }

  componentDidUpdate(prevProps: PublishingOptionsProps) {
    if (prevProps.eventData !== this.props.eventData) {
      this.setState({
        publishOptionId: this.props.eventData.publishMode
      });
    }
  }

  handleConfirm = async () => {
    const { publishOptionId, passwordToggle } = this.state;
    const { updateEventPublishedStatus } = this;

    if (publishOptionId === "public" && !passwordToggle) {
      this.setState({ switchPopup: true });
    } else {
      updateEventPublishedStatus();
    }
  };
  handleClose = () => {
    var publishPopupContainer = document.querySelector(
      ".publishing-options-container"
    );
    //@ts-ignore
    publishPopupContainer.style.display = "none";
    this.setState({ switchPopup: false });
  };

  handleUpdatePrivacy = () => {
    window.location.reload();
  };

  updateEventPublishedStatus = async () => {
    const { closePopup, updateEventPrivacy, eventData } = this.props;
    const { publishOptionId, passwordInput } = this.state;

    try {
      const res = await $.ajax({
        url: `/api/v1/events/${eventData.id}/update`,
        type: "POST",
        data: {
          publish_mode: publishOptionId,
          password: passwordInput
        }
      });

      //@ts-ignore
      toastr.success("Publishing settings updated");
      closePopup ? closePopup() : this.handleClose();
      updateEventPrivacy
        ? updateEventPrivacy(eventData.id, res.event)
        : this.handleUpdatePrivacy();
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update status");
      console.error(err);
    }
  };

  render() {
    const { switchPopup } = this.state;
    const { closePopup, schoolUser, eventData } = this.props;

    return switchPopup ? (
      <PasswordWarningModal
        closePopup={() => (closePopup ? closePopup() : this.handleClose())}
        updateEventPublishedStatus={this.updateEventPublishedStatus}
      />
    ) : (
      <PublishingOptionsPopup
        publishOptionId={this.state.publishOptionId}
        setPublishOptionId={val => this.setState({ publishOptionId: val })}
        passwordToggle={this.state.passwordToggle}
        setToggle={val => this.setState({ passwordToggle: val })}
        passwordInput={this.state.passwordInput}
        setPasswordInput={val => this.setState({ passwordInput: val })}
        handleConfirm={() => this.handleConfirm()}
        closePopup={() => (closePopup ? closePopup() : this.handleClose())}
        eventUrl={eventData.url}
        eventName={eventData.name}
        schoolUser={schoolUser}
      />
    );
  }
}

export default PublishingOptions;

import React, { useContext } from "react";
import CopyBtn from "./vectors/CopyBtn";
import EmailIcon from "./vectors/EmailIcon";
import { EventGalleryContext } from "./index";

interface EventSupportEmailPopupProps {
  showEmail: boolean;
  customerSupportReference: string;
  supportEmail: string;
}

const EventSupportEmailPopup: React.FC<EventSupportEmailPopupProps> = ({
  showEmail,
  customerSupportReference,
  supportEmail
}) => {
  const context = useContext(EventGalleryContext);

  return showEmail && supportEmail ? (
    <>
      <div
        className="geo-email-div geo-email-div-mobile geo-email-support-popover egm-prepaid-div egm-ph-uploading-div"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        {" "}
        <div className="geo-email-details-div">
          <EmailIcon color={context.pageConfiguration.primaryColor} />
          <div className="geo-email-details">
            <span className="title">
              {customerSupportReference
                ? customerSupportReference
                : "Contact Support"}
            </span>
            <a
              href={`mailto:${supportEmail}?cc=support@geosnapshot.com`}
              className="email"
              style={{ color: context.pageConfiguration.primaryColor }}
            >
              {supportEmail}
            </a>
          </div>
        </div>
        <span
          className="copy-icon"
          id="copyIconMobile"
          onClick={() => (
            navigator.clipboard.writeText(`${supportEmail}`), //@ts-ignore
            toastr.success("Copied")
          )}
        >
          <CopyBtn color={context.pageConfiguration.primaryColor} />
        </span>
        <span className="geo-upside-down-triangle"></span>
      </div>
    </>
  ) : null;
};

export default EventSupportEmailPopup;

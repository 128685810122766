import React from "react";
const warnIcon = require("../styles/icon-red-warn.svg");

interface RemoveBundlePromptProps {
  setPromptRemoveOffer: Function;
  removePhotoBundleDiscount: (
    availPrepaidOffer: boolean,
    availResetFilter: boolean
  ) => void;
  hideBundleRemovePrompt: () => void;
  removeSelfie: boolean;
  clearAllFilters: boolean;
  removeFilter: (val: string, newFilter: object | null) => void;
}

const RemoveBundlePrompt = ({
  setPromptRemoveOffer,
  removePhotoBundleDiscount,
  hideBundleRemovePrompt,
  removeSelfie,
  clearAllFilters,
  removeFilter
}: RemoveBundlePromptProps) => {
  const removeOffer = async () => {
    await removePhotoBundleDiscount(false, false);
    clearAllFilters
      ? removeFilter("clearAllFilters", null)
      : removeSelfie &&
        removeFilter("removeSelfie", {
          ["referenceImage"]: null
        });
    setPromptRemoveOffer(false);
  };

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in geo-modal geo-modal--small geo-modal__remove-offer"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          &nbsp;
          <button
            type="button"
            className="close"
            onClick={() => setPromptRemoveOffer(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="geo-modal__header geo-modal--warning-bg">
            <img
              className="geo-modal__warn-icon"
              src={warnIcon}
              alt="exclamation-warn"
            />
            <h4>Are you Sure?</h4>
          </div>
          <div className="geo-modal__body">
            <p>
              If you remove this offer, you will have to pay full price for the
              photos in the cart.
            </p>
          </div>
          <div className="modal-footer geo-modal__actions">
            <a
              className="geo-btn geo-btn--link  geo-btn--caps"
              style={{
                backgroundColor: "#C1C1C1",
                color: "white",
                marginRight: 12,
                marginBottom: 10
              }}
              onClick={async () => removeOffer()}
            >
              Remove Offer
            </a>
            <a
              className="geo-btn geo-btn--secondary geo-btn--caps"
              onClick={async () => {
                await hideBundleRemovePrompt();
                setPromptRemoveOffer(false);
              }}
            >
              Keep Offer
            </a>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default RemoveBundlePrompt;

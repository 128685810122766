import { moment } from "../../utils/fakeMoment";
import React from "react";
import { Event } from "./types";
import "./styles/filters.css";

const LinkedEventsPopup = ({
  linkedEvents,
  closeLinkedEventsPopup
}: {
  linkedEvents: Event[];
  closeLinkedEventsPopup: () => void;
}) => {
  return (
    <div>
      <div
        id="linked-events-dialog"
        className="modal fade in egm ns ns-modal ns-modal-small"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <button
            className="close"
            type="button"
            onClick={() => closeLinkedEventsPopup()}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="filter-content-div egm-linked-content">
            <p className="filter-header-inner">
              {linkedEvents.length} Linked Events
            </p>
            {linkedEvents.map((event: any) => (
              <a
                key={event.id}
                className="egm-linked-single"
                href={event.browsePhotosPath}
              >
                <div className="egm-linked-name">{event.name}</div>
                <div className="egm-linked-date">
                  {moment(event.startDate).format("MMMM DD, YYYY, dddd")}
                </div>
                <div className="egm-linked-location">{event.location}</div>
                <div className="egm-linked-count">
                  {event.albumsCount} Albums
                </div>
                <hr />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default LinkedEventsPopup;

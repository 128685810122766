import React, { useContext } from "react";
import { Event } from "./types";
import _ from "lodash"; // TODO: use lodash-es, import only what's needed
import "../OrganizationEvents/styles/get-notified-popup.css";
import { moment } from "../../utils/fakeMoment";
import BellIcon from "../OrganizationEvents/BellIcon";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";
import { EventGalleryContext } from "./index";

const closeIcon = require("../OrganizationEvents/styles/remove.svg");

const GetNotifiedPopup = ({
  event,
  registerCustomerLead,
  clearSelection
}: {
  event: Event;
  registerCustomerLead: (email: string) => void;
  clearSelection: () => void;
}) => {
  const customerEmailField: any = React.createRef();
  const context = useContext(EventGalleryContext);

  return (
    <div>
      <div id="get-notified-dialog" className="modal fade in" role="dialog">
        {context.pageConfiguration && (
          <div className="modal-dialog get-notified-dialog-content">
            <div className="get-notified-content-div">
              <img
                src={closeIcon}
                onClick={() => clearSelection()}
                className="get-notified-close-icon"
              />
              <div className="get-notified-icon-div">
                <BellIcon color={context.pageConfiguration.primaryColor} />
              </div>
              <p className="get-notified-header">Get Notified</p>
              <p className="get-notified-p">
                Please enter your email to know when photos are uploaded to this
                event.
              </p>
              <div className="get-notified-events">
                <div className="get-notified-ev-card" key={event.id}>
                  <div
                    className={`get-notified-ev-date-badge ${ContrastForegroundUtility.fgClass(
                      context.pageConfiguration.primaryColor
                    )}`}
                  >
                    <p className="get-notified-ev-date-p1">
                      {moment(event.startDate).format("D")}
                    </p>
                    <p className="get-notified-ev-date-p2">
                      {moment(event.startDate).format("MMM")}
                    </p>
                    <p className="get-notified-ev-date-p3">
                      {moment(event.startDate).format("YYYY")}
                    </p>
                  </div>
                  <div>
                    <p className="get-notified-ev-p1">{event.name}</p>
                    <p className="get-notified-ev-p2">{event.location}</p>
                  </div>
                </div>
              </div>
              <div className="get-notified-input-div">
                <input
                  type="text"
                  placeholder="Please enter your email"
                  ref={customerEmailField}
                />
                <button
                  className={`get-notified-btn ${ContrastForegroundUtility.fgClass(
                    context.pageConfiguration.accentColor
                  )}`}
                  onClick={() =>
                    registerCustomerLead(customerEmailField.current.value)
                  }
                >
                  {" "}
                  Notify me{" "}
                </button>
              </div>
            </div>
            <button
              className="get-notified-cancel-btn"
              onClick={clearSelection}
            >
              {" "}
              Cancel
            </button>
          </div>
        )}
      </div>{" "}
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default GetNotifiedPopup;

import React, { useState, useContext, useEffect, useRef } from "react";
import { Album, FilterSelections, Photo } from "./types";
import AlbumPhotos from "./AlbumPhotos";
import ajax from "superagent";
import { EventGalleryContext } from "./index";
import AlbumsList from "./AlbumsList";
import Photos from "./Photos";
import NoMatchingPhotos from "./Filters/NoMatchingPhotos";
import _ from "lodash";
import { removeParam, addParam } from "../../utils/BrowserHelper";
const gssNewLoader = require("../../assets/gss-loader.gif");
import Telemetry from "../../utils/rudderstackAnalytics";
import InvitePhotographerPopup from "../common/InvitePhotographerPopup";

interface Like {
  photoId: number;
  userId: number;
}

const GalleryBody = ({
  albums,
  eventPhotosAPI,
  openedAlbum,
  userInputPassword,
  listOfMediaId,
  userId
}: {
  albums: Album[];
  eventPhotosAPI: string;
  openedAlbum: Album | null;
  userInputPassword: string;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  userId: number | null;
}) => {
  const [activeAlbum, toggleAlbum] = useState<Album | null>(openedAlbum);
  const [filteredPhotos, setFilteredPhotos] = useState<Photo[]>([]);
  const [likedMedia, setLikedMedia] = useState<Like[]>([]);
  const [nextPage, setNextPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [guestSession, setGuestSession] = useState(false);
  const [showInvitePopup, setInvitePopup] = useState(false);

  const context = useContext(EventGalleryContext);

  const mounted = useRef(true);

  const photosRequest = (
    url: string,
    filters: FilterSelections,
    pageNumber: number,
    userIdProp?: number
  ) => {
    let req = ajax.get(url).query({
      page: pageNumber,
      user_id: userId || userIdProp || context.guestUserId,
      cartId: context.cart ? context.cart.id : null
    });

    if (userInputPassword !== "") {
      req = req.query({
        event_password: userInputPassword
      });
    }

    if (filters.photoTime.active) {
      req = req.query({
        minute_from: filters.photoTime.minuteFrom,
        minute_to: filters.photoTime.minuteTo
      });
    }

    if (filters.referenceImage) {
      req = req.query({
        reference_image: filters.referenceImage
      });
    }

    if (filters.albums.length > 0) {
      const albumIdsString = filters.albums
        .map(albumId => `album_ids[]=${albumId}`)
        .join("&");
      req = req.query(albumIdsString);
    }

    if (filters.photoText) {
      req = req.query({
        photo_text: filters.photoText
      });
    }

    if (filters.favorite) {
      req = req.query({
        favorite: true
      });
    }

    if (filters.photographers) {
      req = req.query({
        "album_ids[]": _.map(
          _.filter(
            albums,
            album =>
              //@ts-ignore
              _.indexOf(filters.photographers, album.photographerId) >= 0
          ),
          "id"
        )
      });
    }

    return req;
  };

  const trackAddToFavorite = ({
    photoId,
    source
  }: {
    photoId: number;
    source: string;
  }) => {
    Telemetry.track("event-gallery:add-to-favorites", {
      eventId: context.event.id,
      eventName: context.event.name,
      photoId: photoId,
      source: source
    });
  };

  const addLike = async (photoId: number, userId: number, source: string) => {
    const url = `${context.addPhotoToFavoritesAPI}?photo_id=${photoId}&user_id=${userId}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        trackAddToFavorite({ photoId, source });
        setLikedMedia((prevLikedMedia: Like[]) => [
          ...prevLikedMedia,
          { photoId, userId }
        ]);
        if (
          context.filterSelections.favorite &&
          (context.userId || context.guestUserId)
        ) {
          loadPhotos(context.filterSelections, true);
        }
      } else {
        const data = await response.json();
        if (response.status === 409) {
          //@ts-ignore
          toastr.error(data.error);
          trackAddToFavorite({ photoId, source });
          console.warn("Photo is already liked.");
        } else {
          console.error("Error adding like:", data.error);
        }
      }
    } catch (error) {
      console.error("Error adding like:", error);
    }
  };

  const removeLike = async (photoId: number, userId: number) => {
    const url = `${context.removePhotoFromFavoritesAPI}?photo_id=${photoId}&user_id=${userId}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        setLikedMedia((prevLikedMedia: Like[]) =>
          prevLikedMedia.filter(like => !(like.photoId === photoId))
        );
        if (
          context.filterSelections.favorite &&
          (context.guestUserId || context.userId)
        ) {
          loadPhotos(context.filterSelections, true);
        }
      } else {
        const data = await response.json();
        console.error("Error removing like:", data.error);
      }
    } catch (error) {
      console.error("Error removing like:", error);
    }
  };

  const handleLikeButtonClick = async (
    photoId: number,
    userId: number,
    source: string
  ) => {
    if (!context.guestUserId && !context.userId) {
      await addLike(photoId, userId, source);
      context.setGuestUserId(userId);
      setGuestSession(true);
      if (filteredPhotos.length > 0) {
        setLoading(true);
        loadPhotos(context.filterSelections, true, userId);
      }
    } else {
      const isLiked = likedMedia.some(like => like.photoId === photoId);
      if (isLiked) {
        removeLike(photoId, userId);
      } else {
        addLike(photoId, userId, source);
      }
    }
  };

  const isLikedMedia = (photos: any) => {
    const favoritePhotos = photos.filter(
      (photo: { favorite: boolean }) => photo.favorite
    );

    const likedPhotosArray = favoritePhotos.map(
      (photo: { id: number; userId: number }) => {
        return {
          photoId: photo.id,
          userId: photo.userId || null
        };
      }
    );

    return likedPhotosArray;
  };

  const trackSelfieSearchResults = (isResultsFound: boolean) => {
    if (context.filterSelections.referenceImage) {
      Telemetry.track("event-gallery:selfie-search-completed", {
        photosAvailable: isResultsFound
      });
    }
  };

  const loadPhotos = async (
    filters: FilterSelections,
    reset: boolean = false,
    userIdProp?: number
  ) => {
    context.updatePhotosCount(0);
    try {
      const response = await photosRequest(
        eventPhotosAPI,
        filters,
        reset ? 1 : nextPage,
        userIdProp
      );

      await new Promise<void>(resolve => {
        setFilteredPhotos(previousPhotos => {
          if (reset || context.reset) {
            context.setPhotos(galleryPhotos([...response.body.photos]));
            setLikedMedia(isLikedMedia([...response.body.photos]));
            return galleryPhotos([...response.body.photos]);
          } else {
            context.setPhotos(
              galleryPhotos([...previousPhotos, ...response.body.photos])
            );
            setLikedMedia(
              isLikedMedia([...previousPhotos, ...response.body.photos])
            );
            return galleryPhotos([...previousPhotos, ...response.body.photos]);
          }
        });
        resolve();
      });

      setNextPage(response.body.meta.nextPage);
      context.updatePhotosCount(response.body.meta.totalCount);

      if (response.body.meta.currentPage == 1) {
        if (response.body.meta.totalCount) {
          trackSelfieSearchResults(true);
        } else {
          trackSelfieSearchResults(false);
        }
      }

      context.reloadAlbumPhotos(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (context.activeFilters.length > 0) {
      setLoading(true);
      loadPhotos(context.filterSelections, true);
    } else {
      setFilteredPhotos([]);
      // This is to make sure user doesn't get confused that
      // filters are specific to album
      if (!mounted.current) toggleAlbum(null);
      else mounted.current = false;
      setLoading(false);
    }
  }, [context.filterSelections]);

  useEffect(() => {
    if (context.reset === true) {
      setLoading(true);
      loadPhotos(context.filterSelections, true);
    }
  }, [context.activeFilters.length > 0 ? context.reset : 0]);

  useEffect(() => {
    if (context.activeFilters.length > 0) {
      setFilteredPhotos(galleryPhotos(filteredPhotos));
    }
  }, [listOfMediaId.length, context.cart ? context.cart.photos.length : 0]);

  const noPhotosMatched =
    context.activeFilters.length > 0 && filteredPhotos.length === 0;

  const setAlbum = (album: Album | null) => {
    toggleAlbum(album);
    if (album) addParam("album_id_open", album.signedId);
    else removeParam("album_id_open", window.location.href);
  };

  const updateLikedMedia = (newLikedMedia: Like[]) => {
    setLikedMedia(newLikedMedia);
  };

  const isSelected = (photo: Photo) =>
    _.includes(
      context.curationMode
        ? context.listOfMediaId.map(item => item.id)
        : context.cart
        ? context.cart.photos
        : [],
      photo.id
    );

  const isLiked = (photo: Photo) => {
    return likedMedia.some(like => like.photoId === photo.id);
  };

  const galleryPhotos = (photos: any) => {
    return _.uniqBy(photos, "id").map((photo: any) => {
      return {
        ...photo,
        ...(photo.timeTaken
          ? {
              tags: [
                {
                  title: "Time",
                  value: photo.timeTaken
                }
              ]
            }
          : {}),
        isSelected: isSelected(photo),
        favorite: isLiked(photo),
        src: "",
        disableContextMenu: true
      };
    });
  };
  return (
    <div className="mobile-photos-ht">
      {loading ? (
        <div className="egm-loader gss-loader" style={{ clear: "both" }}>
          {" "}
          <img className="photos-date-img" src={gssNewLoader} />
        </div>
      ) : noPhotosMatched ? (
        <NoMatchingPhotos />
      ) : filteredPhotos.length > 0 ? (
        <div>
          <Photos
            photos={filteredPhotos}
            loadPhotos={() => loadPhotos(context.filterSelections)}
            hasMore={!!nextPage}
            trackTelemetry={true}
            userId={context.userId ? context.userId : undefined}
            isAuthorizedForDirectDownloads={false}
            likedMedia={likedMedia}
            handleLikeButtonClick={handleLikeButtonClick}
            reset={context.filterSelections.favorite ? true : false}
            showFavIcon={true}
          />
        </div>
      ) : activeAlbum ? (
        <AlbumPhotos
          album={activeAlbum}
          toggleAlbum={setAlbum}
          eventPassword={userInputPassword}
          likedMedia={likedMedia}
          handleLikeButtonClick={handleLikeButtonClick}
          isLikedMedia={isLikedMedia}
          updateLikedMedia={updateLikedMedia}
          guestSession={guestSession}
        />
      ) : (
        <AlbumsList albums={albums} toggleAlbum={setAlbum} />
      )}
    </div>
  );
};
export default GalleryBody;

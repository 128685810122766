import React, { useRef, useEffect, useContext } from "react";
import * as EmailValidator from "email-validator";
import { EventGalleryContext } from "./index";
import Telemetry from "../../utils/rudderstackAnalytics";

const EmailPopup = ({
  closePopup,
  registerGuestUserAPI,
  setGuestUserId,
  handleLikeButtonClick,
  currentMedia,
  closeLightBox
}: {
  closePopup: () => void;
  registerGuestUserAPI: string;
  setGuestUserId: (val: number) => void;
  currentMedia: {
    mediaId: number;
    currentState: boolean;
  };
  handleLikeButtonClick: (
    photoId: number,
    userId: number,
    source: string
  ) => void;
  closeLightBox: Function;
}) => {
  const emailInput: any = useRef(null);
  const context = useContext(EventGalleryContext);

  const registerEmailAddress = () => {
    const email = emailInput.current.value.toLowerCase();

    if (EmailValidator.validate(email)) {
      const requestData = { email: email, event_id: context.event.id };

      fetch(registerGuestUserAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then(data => {
          const userId = data.user_id;
          const source = "lightbox";
          closePopup();
          closeLightBox();
          Telemetry.track("event-gallery:guest-favorites-provided-email", {
            eventId: context.event.id,
            eventName: context.event.name,
            photoId: currentMedia.mediaId,
            source: source
          });
          handleLikeButtonClick(currentMedia.mediaId, userId, source);
        })
        .catch(error => {
          console.error("An error occurred while registering the user:", error);
          //@ts-ignore
          toastr.error("An error occurred while registering the user.");
        });
    } else {
      //@ts-ignore
      toastr.error("The email you've entered is invalid.");
    }
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        registerEmailAddress();
      }
    };

    emailInput.current.addEventListener("keypress", handleKeyPress);

    return () => {
      emailInput.current.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  return (
    <div>
      <div
        id="modal-dialog"
        className="modal fade in geo-modal geo-modal--small geo-modal--show geo-multi-modal"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-content">
            <div className="modal-header">
              &nbsp;
              <button
                className="close geo-email-modal-close-icon"
                onClick={() => closePopup()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body email-modal-body">
              <div className="geo-modal__header geo-modal--email-header">
                <h4>Please enter your email address to save favorites</h4>
              </div>
              <div
                className="geo-modal__body geo-modal--email-body"
                style={{ paddingBottom: "25px" }}
              >
                <p className="geo-email-add-p">
                  Click the Favorites icon in the filters panel to access your
                  favorite items at any time
                </p>
                <div className="geo-email-add-input geo-form">
                  <input
                    type="text"
                    className="geo-form__input"
                    placeholder="Email address"
                    ref={emailInput}
                  />

                  <p style={{ fontSize: "12px" }}>
                    You will be notified when all photos have been uploaded.
                  </p>
                </div>
                <button
                  className="geo-btn geo-btn--secondary geo-btn--caps done-btn"
                  onClick={() => registerEmailAddress()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default EmailPopup;

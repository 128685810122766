import React, { useEffect, useRef } from "react";
import photographer from "./images/photographer.svg";
import popup from "./images/popup-bg.png";
import "./styles/style.css";
declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
  }
}

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "42524444",
          formId: "638fb581-cec3-4fe6-814c-42c276602cf8",
          target: "#hubspotForm"
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="hubspotForm"></div>;
};

const InvitePhotographerPopup = ({
  handleCloseClick,
  type
}: {
  handleCloseClick: Function;
  type: string;
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        handleCloseClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCloseClick]);

  return (
    <div>
      <div
        id="modal-dialog"
        className="modal fade in geo-modal geo-modal--small geo-modal--warning geo-modal--show 
        geo-modal--scroll"
        role="dialog"
      >
        <div
          className="modal-dialog filter-dialog-content geo-privacy-body geo-privacy-modal invite-modal"
          ref={popupRef}
        >
          <div
            className="modal-content"
            style={{
              backgroundImage: `url(${popup})`,
              backgroundPositionY: "bottom",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              minHeight: `448px`
            }}
          >
            <div className="modal-header">
              &nbsp;
              <button className="close" onClick={() => handleCloseClick()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="geo-modal__header geo-modal--warning-bg">
              <img
                className="geo-modal__warn-icon"
                src={photographer}
                alt="photographer-icon"
                style={{ width: 180 }}
              />
              <h4>
                Ready to sell more photos and maximize your photography success?
              </h4>
              <span className="modal-subheader">
                Join GeoSnapShot: the number one platform for sports
                photographers. No sign up fees.
              </span>
            </div>
            <div className="geo-modal__body">
              <br />
              <HubSpotForm />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default InvitePhotographerPopup;

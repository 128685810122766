import React, { useEffect, useState, useLayoutEffect } from "react";

const PublishBanner = ({
  openPublishPopup
}: {
  openPublishPopup: Function;
}) => {
  const [hasTranslateClass, setHasTranslateClass] = useState(false);
  const [mobileScreen, setMobileScreen] = useState(false);

  useLayoutEffect(() => {
    const onDOMContentLoaded = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth > 700) {
        setMobileScreen(false);
      } else {
        setMobileScreen(true);
      }
      const hasTranslateClass =
        document.querySelector(".bg-googl-translate") !== null;
      setHasTranslateClass(hasTranslateClass);
    };

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
    } else {
      onDOMContentLoaded();
    }
    return () => {
      document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
    };
  }, []);

  return (
    <h3
      id="publish-warning"
      className="geo-unpublished-warning"
      style={{
        top:
          hasTranslateClass && mobileScreen ? 99 : hasTranslateClass ? 85 : 45
      }}
    >
      <img
        className="gep-unpublished-icon"
        src="/images/website/unpublished.svg"
        style={{ marginRight: "10px" }}
        alt="Unpublished Icon"
      />
      Unpublished{" "}
      <span className="geo-publish-btn" onClick={() => openPublishPopup()}>
        {" "}
        Publish Now
      </span>
      <span
        className="geo-unpublished-help"
        data-toggle="tooltip"
        data-placement="bottom"
        data-original-title="Team & invited photographers can see unpublished photos"
      >
        <img
          className="geo-unpublished-help-img"
          src="/images/website/help.svg"
          alt="Help Icon"
        />
      </span>
    </h3>
  );
};

export default PublishBanner;

import React, { useState, useContext, Fragment, useRef } from "react";
import ReactS3Uploader from "react-s3-uploader";
import ajax from "superagent";
import Color from "color";
import { EventGalleryContext } from "../index";
import UploadIcon from "../vectors/UploadIcon";
import SelfieIcon from "../vectors/SelfieIcon";
import ImageUploadAlert from "../../ImageUploadAlert";
import ContrastForegroundUtility from "../../../utils/ContrastForegroundUtility";
import WebcamCapture from "./WebcamCapture";
const delImage = require("../images/delete.svg");
const infoIcon = require("../images/icon-info-red.svg");
const placeholderImage = require("../../MasterImageSearch/images/placeholder-img-ver2.svg");

const SelfieFilter = ({
  availPhotoBundleDiscount,
  updateFilter,
  closePopup,
  selectedImage,
  bundleDiscountInfo,
  setRemoveFilter,
  trackFilterEvents,
  filterPopUpTitle
}: {
  availPhotoBundleDiscount?: () => void;
  updateFilter: (filter: string | null, clearAllFilters: boolean) => void;
  closePopup: () => void;
  selectedImage: string | null;
  bundleDiscountInfo: boolean;
  setRemoveFilter: (value: string) => void;
  trackFilterEvents?: (filterType: string) => void;
  filterPopUpTitle: "primary" | "secondary";
}) => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const context = useContext(EventGalleryContext);

  const getSignedUrl = (file: any, callback: (json: any) => void) => {
    ajax
      .get("/s3/signed_url")
      .query({
        bucket: context.selfieSearchS3Bucket,
        contentType: file.type,
        cart_id: context.cart ? context.cart.id : null
      })
      .then(data => {
        callback(JSON.parse(data.text));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const imageSelected = (imageFile: any, next: (image: any) => void) => {
    setUploadProgress(0);
    setPreviewImage(URL.createObjectURL(imageFile));
    setProcessing(true);
    next(imageFile);
  };

  const uploadAndFilter = (
    imageFile: string | number | boolean | Blob | Buffer
  ) => {
    setPreviewImage(URL.createObjectURL(imageFile));
    setProcessing(true);
    let n = 10;
    setTimeout(() => {
      setUploadProgress(n);
      n += 30;
    }, 100);

    ajax
      .post("/s3/upload_image")
      .attach("file", imageFile)
      .field("bucket", context.selfieSearchS3Bucket)
      .then(response => {
        updateFilter(response.body.url, false);
        trackFilterEvents && trackFilterEvents("selfie");
        availPhotoBundleDiscount && availPhotoBundleDiscount();
        setUploadProgress(null);
      });
  };

  const uploadBase64Image = (base64Img: RequestInfo) => {
    fetch(base64Img)
      .then(res => res.blob())
      .then(blob =>
        //@ts-ignore
        uploadAndFilter(new File([blob], { type: "image/png" }))
      );
  };

  return (
    <div>
      <div
        id="filter-dialog"
        className="modal fade in egm geo-modal geo-modal--small geo-modal--scroll"
        role="dialog"
      >
        <div className="modal-dialog filter-dialog-content">
          <div className="modal-header">
            <button type="button" className="close" onClick={closePopup}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="filter-content-div">
            {filterPopUpTitle == "primary" ? (
              <p className="filter-header-inner" style={{ marginTop: "-20px" }}>
                {showWebcam ? "Take a Selfie" : "Upload a Selfie"}
              </p>
            ) : (
              <>
                <p className="filter-header-inner filter-header-inner-easy">
                  It's easy to find your photos!
                </p>
                <p className="filter-header-smallt">
                  Simply add a <strong>selfie</strong> to view your matching
                  photos instantly.
                </p>
              </>
            )}

            {context.event.isPhotoIndexingDisabled ? (
              <div className="geo-warning-mobile-popup">
                <i className="fa fa-info-circle"></i>
                <p className="geo-warning-info">
                  Selfie search is unavailable for this event
                </p>
              </div>
            ) : (
              <div>
                {bundleDiscountInfo && (
                  <div className="geo-selfie__info geo-flex">
                    <div className="geo-selfie__icon">
                      <img
                        src={infoIcon}
                        alt=""
                        className="geo-selfie__icon-i"
                      />
                    </div>
                    <div className="geo-selfie__desc">
                      Only photos detected by face recognition can be purchased
                      through the photo bundle. Other photos will need to be
                      purchased separately.
                    </div>
                  </div>
                )}
                {showWebcam ? (
                  <WebcamCapture
                    closeWebcam={() => setShowWebcam(false)}
                    captured={(base64Img: string) => {
                      setShowWebcam(!showWebcam);
                      uploadBase64Image(base64Img);
                    }}
                    primaryColor={context.pageConfiguration.primaryColor}
                  />
                ) : (
                  <>
                    <div className="filter-selfie-img-div">
                      {previewImage ? (
                        <img src={previewImage} className="uploading" />
                      ) : selectedImage ? (
                        <Fragment>
                          <img src={selectedImage} className="selected" />
                          <button
                            onClick={async () => (
                              setRemoveFilter("removeSelfie"),
                              updateFilter(null, false)
                            )}
                            className="filter-delete-selfie"
                          >
                            <img src={delImage} />
                          </button>
                        </Fragment>
                      ) : (
                        <img className="bit-m" src={placeholderImage} />
                      )}
                      {uploadProgress && uploadProgress < 100 ? (
                        <div className="uploader">
                          <span
                            className="uploader-fill"
                            style={{ height: `${uploadProgress}%` }}
                          ></span>
                          <span className="uploader-count">
                            {uploadProgress}%
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <p className="image-upload-terms" style={{ marginTop: 10 }}>
                      Please upload or take a photo looking straight ahead to
                      find matching photos.
                    </p>
                    <div className="selfie-btns">
                      <label
                        className="btn btn-primary selfie-up-btn"
                        style={{
                          color: ContrastForegroundUtility.fgColor(
                            context.pageConfiguration.primaryColor
                          ),
                          background: context.pageConfiguration.primaryColor,
                          boxShadow: `0px 14px 30px ${Color(
                            context.pageConfiguration.primaryColor
                          ).fade(0.75)}`
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        <SelfieIcon
                          color={ContrastForegroundUtility.fgColor(
                            context.pageConfiguration.primaryColor
                          )}
                        />{" "}
                        <span>Take A Selfie</span>
                      </label>

                      {!context.hideUploadSelfie && (
                        <label
                          className="btn selfie-up-btn"
                          style={{
                            color: ContrastForegroundUtility.fgColor(
                              context.pageConfiguration.secondaryColor
                            ),
                            background:
                              context.pageConfiguration.secondaryColor,
                            boxShadow: `0px 14px 30px ${Color(
                              context.pageConfiguration.secondaryColor
                            ).fade(0.75)}`
                          }}
                        >
                          <UploadIcon
                            color={ContrastForegroundUtility.fgColor(
                              context.pageConfiguration.secondaryColor
                            )}
                          />{" "}
                          <span>Upload Photo</span>
                          <ReactS3Uploader
                            className={"dv-upload-input"}
                            onClick={() =>
                              context.cart &&
                              context.cart.photoBundleDiscountAvailed &&
                              context.cart.photos.length > 0
                                ? //@ts-ignore
                                  (window.location = `${context.event.browsePhotosPath}?clear-cart-prompt=true`)
                                : null
                            }
                            style={{ display: "none" }}
                            getSignedUrl={getSignedUrl}
                            accept="image/*"
                            preprocess={imageSelected}
                            onProgress={completedPercent => {
                              setUploadProgress(completedPercent);
                            }}
                            onError={console.error}
                            onFinish={async result => {
                              if (trackFilterEvents) {
                                trackFilterEvents("selfie");
                              }
                              await updateFilter(
                                result.signedUrl.split("?")[0],
                                false
                              );
                              availPhotoBundleDiscount &&
                                (await availPhotoBundleDiscount());
                              setUploadProgress(null);
                            }}
                            uploadRequestHeaders={{
                              "x-amz-acl": "public-read"
                            }}
                            scrubFilename={(filename: string) =>
                              filename.replace(/[^\w\d_\-.]+/gi, "")
                            }
                            contentDisposition="auto"
                          />
                        </label>
                      )}
                    </div>
                    {!previewImage ? <ImageUploadAlert /> : null}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default SelfieFilter;

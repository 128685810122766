import React from "react";

const LockIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.71556 0C7.34967 0 6.03973 0.542595 5.0739 1.50842C4.10808 2.47425 3.56548 3.78419 3.56548 5.15007V7.14761L2.7236 7.14761C2.00146 7.14827 1.30909 7.43543 0.798456 7.94606C0.287826 8.45669 0.000663453 9.14907 0 9.87121V17.4973C0.000663453 18.2194 0.287826 18.9118 0.798456 19.4224C1.30909 19.933 2.00146 20.2202 2.7236 20.2209H14.7074C15.4296 20.2202 16.1219 19.933 16.6326 19.4224C17.1432 18.9118 17.4304 18.2194 17.431 17.4973V9.87121C17.4304 9.14907 17.1432 8.45669 16.6326 7.94606C16.1219 7.43543 15.4296 7.14827 14.7074 7.14761H13.8656V5.15007C13.8656 3.78419 13.323 2.47425 12.3572 1.50842C11.3914 0.542595 10.0814 0 8.71556 0ZM6.45658 7.14761L6.45659 5.15007L6.45656 5.14534C6.45478 4.84755 6.5119 4.55234 6.62463 4.27671C6.73735 4.00107 6.90347 3.75044 7.11341 3.53924C7.32335 3.32804 7.57298 3.16043 7.84794 3.04606C8.1229 2.93169 8.41776 2.87281 8.71556 2.87281C9.01335 2.87281 9.30821 2.93169 9.58317 3.04606C9.85812 3.16043 10.1078 3.32804 10.3177 3.53924C10.5276 3.75044 10.6938 4.00107 10.8065 4.27671C10.9192 4.55234 10.9763 4.84755 10.9745 5.14534V7.14761L6.45658 7.14761ZM5.66421 7.14751V6.35519H5.66425V7.14751H5.66421ZM9.26023 15.3184C9.26023 15.4629 9.20284 15.6014 9.10068 15.7036C8.99853 15.8057 8.85998 15.8631 8.71551 15.8631C8.57104 15.8631 8.43249 15.8057 8.33033 15.7036C8.22818 15.6014 8.17079 15.4629 8.17079 15.3184V12.0501C8.17079 11.9056 8.22818 11.7671 8.33033 11.6649C8.43249 11.5628 8.57104 11.5054 8.71551 11.5054C8.85998 11.5054 8.99853 11.5628 9.10068 11.6649C9.20284 11.7671 9.26023 11.9056 9.26023 12.0501V15.3184Z"
        fill={color}
      />
    </svg>
  );
};
export default LockIcon;

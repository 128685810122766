import React from "react";
const warnIcon = require("./styles/icon-red-warn.svg");

const PasswordWarningModal = function ({
  closePopup,
  updateEventPublishedStatus
}: {
  closePopup: Function;
  updateEventPublishedStatus: Function;
}) {
  return (
    <div>
      <div id="modal-dialog" className="modal fade in geo-modal geo-modal--small geo-modal--warning geo-modal--show" role="dialog">
        <div className="modal-dialog filter-dialog-content geo-privacy-body geo-privacy-modal">
          <div className="modal-content">
            <div className="modal-header">
              &nbsp;
              <button className="close" onClick={() => closePopup()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="geo-modal__header geo-modal--warning-bg">
                <img
                  className="geo-modal__warn-icon"
                  src={warnIcon}
                  alt="exclamation-warn"
                />
                <h4>Are you sure?</h4>
              </div>
              <div className="geo-modal__body">
                <p>Anyone on the Internet with the link can access this event.</p>
                <button
                  className="geo-btn geo-btn--secondary geo-btn--caps geo-btn--small"
                  onClick={e => {
                    e.preventDefault();
                    updateEventPublishedStatus();
                  }}
                  style={{ marginTop: 15 }}
                >
                  I understand, continue
                </button>
                <br />
                <button
                  className="geo-btn geo-btn--link geo-btn--caps geo-btn--small"
                  onClick={e => {
                    e.preventDefault();
                    closePopup();
                  }}
                  style={{ marginTop: 15 }}
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade in" />
    </div>
  );
};

export default PasswordWarningModal;

import React, { useState, useContext, useEffect } from "react";
import { Album, Event, Photo } from "./types";
// @ts-ignore
import Gallery from "react-grid-gallery";
import _ from "lodash";
// @ts-ignore
import ahoy from "ahoy.js";
import LightBox from "./LightBox";
import { EventGalleryContext } from "./index";
import { FindPhotosGalleryContext } from "../MasterImageSearch/components/results/PhotosContainer";
import ajax from "superagent";
import EmailPopup from "./EmailPopup";
const playIcon = require("./styles/play.svg");
const hideIcon = require("./styles/imgHide.svg");
import Telemetry from "../../utils/rudderstackAnalytics";

import {
  CartOutline,
  CartFilled
} from "../BrowseEvent/components/vectors/AddToCartIcon";
import {
  CheckOutline,
  CheckFilled
} from "../BrowseEvent/components/vectors/CheckIcon";

const MobilePhotoGallery = ({
  photos,
  trackTelemetry,
  isAuthorizedForDirectDownloads,
  userId,
  reset,
  primaryColor,
  likedMedia,
  handleLikeButtonClick,
  parentName,
  showFavIcon
}: {
  photos: Photo[];
  trackTelemetry: boolean;
  isAuthorizedForDirectDownloads: boolean;
  userId?: number;
  reset?: boolean;
  primaryColor?: string;
  likedMedia: Array<{ photoId: number; userId: number }>;
  handleLikeButtonClick: (
    photoId: number,
    userId: number,
    source: string
  ) => void;
  parentName?: string;
  showFavIcon?: boolean;
}) => {
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [curationApiCalled, setCurationApiCalled] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [currentMedia, setCurrentMedia] = useState<{
    mediaId: number;
    currentState: boolean;
  }>();
  const [showLightBox, setShowLightBox] = useState(false);
  const [photosState, setphotos] = useState<Photo[]>([]);
  const context = useContext(EventGalleryContext);
  const findPhotosContext = useContext(FindPhotosGalleryContext);

  useEffect(() => {
    if (reset) {
      setShowLightBox(false), setPhotoIndex(-1);
    }
    setphotos(photos);
  }, [photos]);

  interface ImageComponentProps {
    props: {
      index: number;
      item: {
        videoContent: any;
        videoDuration: string | number;
        thumbnail: string | undefined;
        id: number;
        album: { name: string };
        hidden: boolean;
        favorite: boolean;
        isSelected: boolean;
      };
      imageProps: JSX.IntrinsicAttributes &
        React.ClassAttributes<HTMLImageElement> &
        React.ImgHTMLAttributes<HTMLImageElement>;
    };
    handleImageSelect: ({
      photo,
      index
    }: {
      photo: any;
      index: number;
    }) => void;
    primaryColor: string;
    shouldShowCheckIcon: boolean;
  }

  const trackGuestFavoriteEmailPrompted = (photoId: number, source: string) => {
    Telemetry.track("event-gallery:guest-favorites-email-prompted", {
      eventId: context.event.id,
      eventName: context.event.name,
      photoId: photoId,
      source: source
    });
  };

  const ImageComponent = ({
    props,
    handleImageSelect,
    primaryColor,
    shouldShowCheckIcon
  }: ImageComponentProps) => {
    const ImageSelectIcon = (
      <div
        className="geo-cart-icon-container"
        onClick={e => {
          e.stopPropagation();
          handleImageSelect({ photo: props.item, index: props.index });
        }}
      >
        {shouldShowCheckIcon ? (
          props.item.isSelected ? (
            <CheckFilled color={primaryColor} />
          ) : (
            <CheckOutline />
          )
        ) : props.item.isSelected ? (
          <CartFilled color={primaryColor} />
        ) : (
          <CartOutline />
        )}
      </div>
    );

    return (
      <div style={{ textAlign: "center", cursor: "pointer" }}>
        {props.item.hidden && (
          <span className="visibility-stamp">
            <img src={hideIcon} />
          </span>
        )}
        {props.item.videoContent && (
          <img
            className="play-icon"
            src={playIcon}
            alt={`${props.item.album.name}-${props.item.id}`}
          />
        )}
        {ImageSelectIcon}
        <img
          {...props.imageProps}
          alt={`${props.item.album.name}-${props.item.id}`}
        />
      </div>
    );
  };

  const updatePhotoInCart = (photo: any) => {
    if (photo.isSelected) {
      if (trackTelemetry) {
        Telemetry.track("event-gallery:remove-from-cart", {
          eventId: context.event?.id,
          eventName: context.event?.name,
          photoId: photo.id,
          cartId: context.cart?.id,
          source: "thumbnail",
          embeddedMode: context.isEmbed
        });
      }
      if (context.cart) context.removePhotoFromCart(photo);
      else findPhotosContext.removePhotoFromCart(photo);
    } else {
      if (trackTelemetry) {
        Telemetry.track("event-gallery:add-to-cart", {
          eventId: context.event?.id,
          eventName: context.event?.name,
          photoId: photo.id,
          cartId: context.cart?.id,
          source: "thumbnail",
          embeddedMode: context.isEmbed
        });
      }
      if (context.event) {
        // considering this context is from MobileEventGallery
        // Needs to be Refactored
        context.addPhotoToCart(photo);
      } else {
        findPhotosContext.addPhotoToCart(photo);
      }
    }
  };

  useEffect(() => {
    if (photoIndex >= 0) {
      setShowLightBox(true);
    }
  }, [photoIndex]);

  const toggleLightBox = (index: number) => {
    if (photos[photoIndex] && photos[photoIndex].videoContent) {
      setShowLightBox(false);
    }
    setPhotoIndex(index);
    if (index == -1) {
      setShowLightBox(false);
    }
    if (index >= 0) {
      if (!context.guestUserId) {
        setCurrentMedia({
          mediaId: photos[index].id,
          currentState: photos[index].favorite
        });
      }
      if (trackTelemetry) {
        ahoy.track("albumInteraction", {
          secondary_type: "openedPhotoLightbox",
          album_id: photos[index].album.id,
          event_id: photos[index].album.eventId
        });
        Telemetry.track("event-gallery:opened-lightbox", {
          eventId: context.event.id,
          eventName: context.event.name,
          photoId: photos[index].id,
          embeddedMode: context.isEmbed
        });
      }
    }
  };

  const contextMenu = (e: any) => {
    e.preventDefault();
    return false;
  };

  const tagStyle = {
    display: "inline",
    padding: ".2em .6em .3em",
    fontSize: "60%",
    fontWeight: "600",
    lineHeight: "1",
    color: "#FFF",
    background: "rgba(7,36,22,.6)",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".25em"
  };

  const handleImageSelect = ({
    photo,
    index
  }: {
    photo: any;
    index: number;
  }) => {
    context.curationMode
      ? //@ts-ignore
        (setphotos(context.getPhotoId(photo, index, photos)),
        context.getPhotoId(photo, index, photos))
      : updatePhotoInCart(photo);
  };

  return (
    <div className="dv-album-wrap">
      <Gallery
        images={photosState && photosState.length > 0 ? photosState : photos}
        rowHeight={120}
        margin={5}
        enableLightbox={false}
        enableImageSelection={false}
        onClickThumbnail={(index: number) => {
          toggleLightBox(index);
        }}
        tagStyle={tagStyle}
        tileViewportStyle={customThumbViewportStyle}
        thumbnailStyle={customThumbStyle}
        selectedIconColor={
          primaryColor
            ? primaryColor
            : context.pageConfiguration
            ? context.pageConfiguration.primaryColor
            : "#259FA5"
        }
        enableKeyboardInput={true}
        onContextMenu={contextMenu}
        onRender={() => null}
        thumbnailImageComponent={(props: any) => {
          return (
            <ImageComponent
              props={props}
              handleImageSelect={handleImageSelect}
              primaryColor={
                primaryColor
                  ? primaryColor
                  : context.pageConfiguration
                  ? context.pageConfiguration.primaryColor
                  : "#259FA5"
              }
              shouldShowCheckIcon={context.curationMode}
            />
          );
        }}
      />
      {showLightBox && (
        <LightBox
          photos={photosState && photosState.length > 0 ? photosState : photos}
          photoIndex={photoIndex}
          toggleLightBox={toggleLightBox}
          trackTelemetry={trackTelemetry}
          isAuthorizedForDirectDownloads={isAuthorizedForDirectDownloads}
          userId={userId}
          primaryColor={primaryColor}
          curationApiCalled={curationApiCalled}
          setCurationApiCalled={(val: boolean) => setCurationApiCalled(val)}
          setEmailModal={setEmailModal}
          handleLikeButtonClick={handleLikeButtonClick}
          parentName={parentName}
          showFavIcon={showFavIcon}
          trackGuestFavoriteEmailPrompted={trackGuestFavoriteEmailPrompted}
        />
      )}
      {emailModal && (
        <EmailPopup
          closePopup={() => {
            // add class to handle overlapping issue on ios devices
            document.body.classList.remove("fav-email-popup-open");
            setEmailModal(false);
          }}
          registerGuestUserAPI={context.registerGuestUserAPI}
          setGuestUserId={context.setGuestUserId}
          handleLikeButtonClick={handleLikeButtonClick}
          currentMedia={currentMedia!}
          closeLightBox={() => (setShowLightBox(false), setPhotoIndex(-1))}
        />
      )}
    </div>
  );
};

const customThumbViewportStyle = function () {
  // @ts-ignore
  const item = this.props.item;
  return {
    width: item.vwidth,
    minWidth: 60,
    height: item.height,
    overflow: "hidden",
    position: "initial"
  };
};

const customThumbStyle = function () {
  // @ts-ignore
  const item = this.props.item;
  return {
    cursor: "pointer",
    width: item.scaletwidth,
    height: item.height,
    marginLeft: item.marginLeft,
    marginTop: 0,
    WebkitTouchCallout: "none"
  };
};

export default MobilePhotoGallery;

import React, { Component } from "react";
import Color from "color";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";

interface StyleOverridesMobileProps {
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
}

interface StyleOverridesMobileState {
  fgColor: string;
  fgSecondaryColor: string;
  fgAccentColor: string;
  mobileBannerColor: string;
  gradientColor: string;
  fgTextColor: string;
}

class StyleOverridesMobile extends Component<
  StyleOverridesMobileProps,
  StyleOverridesMobileState
> {
  constructor(props: StyleOverridesMobileProps) {
    super(props);
    this.state = {
      fgColor: "",
      fgAccentColor: "",
      fgSecondaryColor: "",
      mobileBannerColor: "",
      gradientColor: "",
      fgTextColor: ""
    };
  }

  public componentDidMount() {
    this.setState({
      fgColor: ContrastForegroundUtility.fgColor(this.props.primaryColor),
      fgTextColor: ContrastForegroundUtility.fgColor(
        ContrastForegroundUtility.lightenColor(this.props.primaryColor)
      ),
      fgAccentColor: ContrastForegroundUtility.fgColor(this.props.accentColor),
      fgSecondaryColor: ContrastForegroundUtility.fgColor(
        this.props.secondaryColor
      ),
      mobileBannerColor: ContrastForegroundUtility.lightenColor(
        this.props.primaryColor
      ),
      gradientColor: Color(this.props.primaryColor).darken(0.2).hex()
    });
  }

  render() {
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .egm-prepaid-div,
      .egm-ph-uploading-div {
          background: ${this.state.mobileBannerColor};
      }
      .geo-upside-down-triangle{
        border-top: 8px solid ${this.state.mobileBannerColor};
      }
      .egm-offer-text, .egm-promo-banner-text {
        color: ${this.state.fgTextColor};
      }
      .egm-multi-wrap hr {
        border-color: ${this.state.fgTextColor};
      }
      .egm-ph-uploading-btn {
        background: ${this.props.primaryColor};
        color: ${this.state.fgColor};
      }
      .get-notified-ev-date-badge {
        background: ${this.props.primaryColor};
      }
      .dash-border {
        border: 1px dashed ${this.state.mobileBannerColor};
        border-radius: 14px;
        padding: 2px;
      }
      .get-notified-btn {
        background: ${this.props.accentColor};
        color: ${this.state.fgAccentColor};
      }
      .egm-prepaid-btn {
        background: ${this.props.primaryColor};
        color: ${this.state.fgColor};
      }
      .dropdown.egm-prepaid-btn {
        color: ${this.state.fgColor};
        background: ${this.props.primaryColor};
      }
      .geo-btn--secondary-ghost{
        border-color: ${this.props.primaryColor} !important; 
      }
      .geo-btn--secondary-ghost:focus{
        color: ${this.state.fgColor}!important;
        background: ${this.props.primaryColor} !important;
      }
      .geo-btn--secondary-ghost:hover{
        color: ${this.state.fgColor} !important;
        background: ${this.props.primaryColor} !important;
      }
      .slider-selection,
      .slider-handle {
        box-shadow: inset 0 0 0 4px ${this.props.primaryColor} !important;
      }
      .offer-active-mob {
        position: relative;
        z-index: 100;
        background-color: ${this.props.primaryColor};
        font-size: 9.5px;
        bottom: 26px;
        width:fit-content;
        padding: 0px 6px;
        border-radius: 3px;
        border: 1px solid white;
        color: ${this.state.fgColor};
        margin-bottom: -24px;        
      }
    `
        }}
      />
    );
  }
}

export default StyleOverridesMobile;

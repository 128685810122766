import React, { useContext, useState } from "react";
import { Event } from "./types";
import SelfieFilter from "./Filters/SelfieFilter";
import { EventGalleryContext } from "./index";
const selfieIllus = require("./images/selfi-illustration.svg");

const PasswordProtected = ({
  updateFilters,
  trackPrivateEventSelfieUploadFlow,
  trackSelfieFilterApplied
}: {
  updateFilters: (
    newFilters: { [x: string]: any },
    clearFilters: boolean
  ) => void;
  trackPrivateEventSelfieUploadFlow: (trackEventName: string) => void;
  trackSelfieFilterApplied: () => void;
}) => {
  const [selfieUploadPopup, toggleSelfieUploadPopup] = useState(false);
  const context = useContext(EventGalleryContext);

  return (
    <div className="col-md-9 geo-private-wrap-outer">
      <div className="geo-private-wrap ns gpw-m">
        <h5 className="geo-private-head">
          {" "}
          <img src={selfieIllus} className="geo-private-lock" />
          <strong className="geo-private-title">
            It's easy to find your photos!
          </strong>
        </h5>
        <p className="geo-private-desc">
          Simply upload a <strong>selfie</strong> to view your matching photos
          instantly.
        </p>
        <button
          className="btn btn-primary geo-private-btn"
          onClick={() => {
            trackPrivateEventSelfieUploadFlow(
              "event-gallery:private-event:selfie-button-clicked"
            );
            context.showPrepaidClearCartWarning
              ? context.promptClearPrepaidCart()
              : toggleSelfieUploadPopup(true);
          }}
        >
          {context.hideUploadSelfie ? "Take" : "Upload"} a Selfie
        </button>
        {selfieUploadPopup && (
          <SelfieFilter
            updateFilter={imageURL => {
              updateFilters(
                {
                  referenceImage: imageURL
                },
                false
              );
              toggleSelfieUploadPopup(false);
              trackPrivateEventSelfieUploadFlow(
                "event-gallery:private-event:selfie-uploaded"
              );
              trackSelfieFilterApplied();
            }}
            closePopup={() => toggleSelfieUploadPopup(false)}
            selectedImage={""}
            bundleDiscountInfo={false}
            setRemoveFilter={context.setRemoveFilter}
            filterPopUpTitle="primary"
          />
        )}
      </div>
    </div>
  );
};

export default PasswordProtected;

import React from "react";
import ajax from "superagent";
import { moment } from "../../../../utils/fakeMoment";
import _ from "lodash"; // TODO: import only whats needed from lodash-es
import Photos from "./Photos";
import MobilePhotos from "../../../EventGalleryMobile/Photos";
import { Cart, Photo, Filters, Event } from "../types";
import {
  SelfieSearchContext,
  SelfieSearchContextType
} from "../SelfieSearchContext";
import GroupByCountry from "../GroupByCountry";
// import GroupByEvents from "../GroupByEvents"; // TODO: unused - remove
const greenFilter = require("../../images/green-filter.svg");
const noimgs = require("../../images/dark-no-imgs.svg");
// const gssNewLoader = require("../../images/gss-loader.gif"); // TODO: unused - remove

interface PhotosContainerProps {
  photosAPI: string;
  cart: Cart;
  addPhotoToCart: (photo: Photo) => void;
  removePhotoFromCart: (photo: Photo) => void;
  filters: Filters;
  updateFilterOptions: (filters: any) => void;
  openFiltersPopup: () => void;
}

interface Photographer {
  id: number;
  name: string;
  albumsCount: number;
}

interface PhotosContainerState {
  photos: Photo[];
  totalCount: number;
  enableEventGrouping: boolean;
  selectedCountry: string;
  loadingPhotos: boolean;
  nextPage: number;
}

interface FindPhotosGallery {
  cart: Cart | any;
  addPhotoToCart: (photo: Photo) => void;
  removePhotoFromCart: (photo: Photo) => void;
  isEmbed: boolean;
  primaryColor: string;
}

export const FindPhotosGalleryContext = React.createContext<FindPhotosGallery>({
  cart: {},
  addPhotoToCart: () => null,
  removePhotoFromCart: () => null,
  isEmbed: false,
  primaryColor: "#259fa5"
});

class PhotosContainer extends React.Component<
  PhotosContainerProps,
  PhotosContainerState,
  SelfieSearchContextType
> {
  public static contextType = SelfieSearchContext;
  public context!: React.ContextType<typeof SelfieSearchContext>;

  constructor(props: PhotosContainerProps, context: SelfieSearchContextType) {
    super(props, context);

    this.state = {
      photos: [],
      totalCount: 0,
      enableEventGrouping: false,
      selectedCountry: "",
      loadingPhotos: false,
      nextPage: 0
    };
  }

  public componentDidMount() {
    this.loadPhotos(this.props.filters, true);
    this.listenToScroll();
    this.setState({
      selectedCountry: this.context.currentUserCountry
    });
  }

  public componentWillReceiveProps(nextProps: PhotosContainerProps) {
    if (
      (!_.isEqual(
        this.props.filters.referenceImage,
        nextProps.filters.referenceImage
      ) ||
        this.props.filters.confidenceLevel !==
          nextProps.filters.confidenceLevel ||
        this.props.filters.purchasedEventsFilter !==
          nextProps.filters.purchasedEventsFilter) &&
      nextProps.filters.referenceImage
    ) {
      this.loadPhotos(nextProps.filters, true);
    }
  }

  public render() {
    return (
      <div>
        <div className="results-top d-flex">
          <div
            className="selfie-img-m visible-sm visible-xs"
            style={{
              backgroundImage: `url(${this.props.filters.referenceImage})`
            }}
          ></div>
          <GroupByCountry
            photosCount={this.filteredPhotos().length}
            selectedCountry={this.state.selectedCountry}
            countries={this.uniqCountries()}
            updateCountry={this.updateCountry}
          />
          <a
            className="changebtn btn secondary-btn btn-small visible-sm visible-xs"
            onClick={this.context.openSelfieUploadPopup}
          >
            Change
          </a>
          <hr className="visible-sm visible-xs" />
          <a
            className="mob-filters visible-xs visible-sm"
            onClick={this.props.openFiltersPopup}
          >
            <img src={greenFilter} alt="" /> Filters{" "}
            <span className="fa fa-angle-down"></span>
          </a>
        </div>{" "}
        {this.renderPhotos()}
        <div
          className="album-end-marker"
          ref={el => {
            // @ts-ignore
            this.endMarkerEl = el;
          }}
        ></div>
        <div className="dv-section-footer">
          {this.hasMorePhotos() ? this.footer() : null}
        </div>
      </div>
    );
  }

  private photosRequest = (
    url: string,
    filters: Filters,
    pageNumber: number
  ) => {
    let req = ajax.get(url).query({
      page: pageNumber,
      user_id: this.context.currentUserId
    });

    if (filters.referenceImage) {
      req = req.query({
        reference_image: filters.referenceImage
      });
    }

    if (filters.confidenceLevel) {
      req = req.query({
        match_threshold: filters.confidenceLevel
      });
    }

    if (filters.purchasedEventsFilter) {
      req = req.query({
        purchased_events_filter: filters.purchasedEventsFilter
      });
    }
    return req;
  };

  private loadPhotos = (filters: Filters, reset = false) => {
    if (reset) this.setState({ loadingPhotos: true, photos: [] });

    this.photosRequest(
      this.props.photosAPI,
      filters,
      reset ? 1 : this.state.nextPage
    )
      .then(response => {
        this.setState(
          {
            photos: _.filter(
              _.concat(this.state.photos, response.body.photos),
              photo => !!photo.watermarkedImg
            ),
            nextPage: response.body.meta.nextPage,
            totalCount: response.body.meta.totalCount,
            loadingPhotos: false
          },
          () => {
            this.props.updateFilterOptions({
              photographers: this.photographersForFilter(this.state.photos)
            });
          }
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  private photographersForFilter = (photos: Photo[]) => {
    return _.uniqBy(
      _.map(photos, photo => {
        return {
          id: photo.album.photographerId,
          name: photo.album.photographerName,
          albumsCount: _.filter(
            photos,
            p => p.album.photographerId === photo.album.photographerId
          ).length
        };
      }),
      "id"
    );
  };

  private filteredPhotos = () => {
    if (_.isEmpty(this.props.filters.photographers))
      return this.filterByCountry(this.state.photos);
    else
      return this.filterByCountry(
        _.filter(this.state.photos, (photo: Photo) =>
          _.includes(
            this.props.filters.photographers,
            photo.album.photographerId
          )
        )
      );
  };

  private filterByCountry = (photos: Photo[]) =>
    this.state.selectedCountry === "Worldwide"
      ? photos
      : _.filter(photos, photo => photo.country === this.state.selectedCountry);

  private uniqCountries = () =>
    _.uniq(this.state.photos.map(photo => photo.country));

  private uniqEvents = () =>
    _.uniqWith(
      this.filteredPhotos().map((photo: Photo) => photo.album.event),
      _.isEqual
    );

  private eventPhotos = (eventId: number) =>
    _.filter(
      this.filteredPhotos(),
      (photo: Photo) => photo.album.event.id === eventId
    );

  private hasMorePhotos = () => !!this.state.nextPage;

  private updateCountry = (country: string) =>
    this.setState({ selectedCountry: country });

  private toggleEventGrouping = () =>
    this.setState({
      enableEventGrouping: !this.state.enableEventGrouping
    });

  private footer = () => {
    return (
      this.state.loadingPhotos && (
        <div className="dv-section-footer loading">
          <a>
            LOADING <span className="fa fa-circle-o-notch fa-spin" />
          </a>
        </div>
      )
    );
  };

  private listenToScroll = () => {
    // @ts-ignore
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio !== 0 && this.hasMorePhotos()) {
            this.loadPhotos(this.props.filters);
            this.setState({ loadingPhotos: true });
          }
          if (!this.hasMorePhotos()) observer.disconnect();
        });
      },
      { rootMargin: "400px 0px 0px 0px" }
    );
    // @ts-ignore
    this.observer.observe(this.endMarkerEl);
  };
  private isSelected = (photo: Photo) =>
    _.includes(this.props.cart ? this.props.cart.photos : [], photo.id);

  private galleryPhotos = (photos: any) => {
    return _.uniqBy(photos, "id").map((photo: any) => {
      return {
        ...photo,
        ...(photo.timeTaken
          ? {
              tags: [
                {
                  title: "Time",
                  value: photo.timeTaken
                }
              ]
            }
          : {}),
        isSelected: this.isSelected(photo),
        src: "",
        disableContextMenu: true
      };
    });
  };

  private renderPhotos() {
    if (this.state.loadingPhotos && this.filteredPhotos().length === 0) {
      return (
        <div className="dv-loader">
          <div className="dv-loader-inner">
            <div className="cssload-spin-box" />
          </div>
        </div>
      );
    } else if (this.filteredPhotos().length === 0) {
      return (
        <div className="dv-event-no-albums">
          <div className="none-wrap">
            <img src={noimgs}></img>
            <p>
              There are no photos matching the uploaded face image. Try with a
              different photo.
            </p>
          </div>
        </div>
      );
    } else if (this.state.enableEventGrouping) {
      return this.uniqEvents().map((event: Event, index: number) => (
        <div key={index}>
          <div className="event-group-heading">
            <h4>
              <a target="_blank" href={event.browsePhotosPath}>
                {event.name} <span className="fa fa-angle-right"></span>
              </a>
            </h4>
            <p>
              {moment(event.startDate).format("DD MMM, YY")}
              <span className="divi">•</span>
              {event.location}
            </p>
          </div>
          <Photos
            photos={this.eventPhotos(event.id)}
            cart={this.props.cart}
            addPhotoToCart={this.props.addPhotoToCart}
            removePhotoFromCart={this.props.removePhotoFromCart}
            loadMorePhotos={() => {
              if (this.hasMorePhotos()) this.loadPhotos(this.props.filters);
            }}
            isEmbed={false}
            hasMorePhotos={this.hasMorePhotos()}
            isAuthorizedForDirectDownloads={false}
            parentName={"my-snaps-lightbox"}
          />{" "}
          <hr />
        </div>
      ));
    } else if (/Mobi/i.test(window.navigator.userAgent)) {
      return (
        <FindPhotosGalleryContext.Provider
          value={{
            cart: this.props.cart,
            addPhotoToCart: this.props.addPhotoToCart,
            removePhotoFromCart: this.props.removePhotoFromCart,
            isEmbed: false,
            primaryColor: "#259fa5"
          }}
        >
          <MobilePhotos
            photos={this.galleryPhotos(this.filteredPhotos())}
            loadPhotos={() => {
              if (this.hasMorePhotos()) this.loadPhotos(this.props.filters);
            }}
            hasMore={this.hasMorePhotos()}
            trackTelemetry={false}
            isAuthorizedForDirectDownloads={false}
            likedMedia={[]}
            userId={undefined}
            primaryColor={""}
            handleLikeButtonClick={() => {}}
            reset={false}
            parentName={"my-snaps-lightbox"}
          />
        </FindPhotosGalleryContext.Provider>
      );
    } else {
      return (
        <Photos
          photos={this.filteredPhotos()}
          cart={this.props.cart}
          addPhotoToCart={this.props.addPhotoToCart}
          removePhotoFromCart={this.props.removePhotoFromCart}
          loadMorePhotos={() => {
            if (this.hasMorePhotos()) this.loadPhotos(this.props.filters);
          }}
          isEmbed={false}
          hasMorePhotos={this.hasMorePhotos()}
          isAuthorizedForDirectDownloads={false}
          parentName={"my-snaps-lightbox"}
        />
      );
    }
  }
}

export default PhotosContainer;

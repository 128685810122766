import React, { useContext, Fragment, useState } from "react";
import { EventGalleryContext } from "./index";
import NotiIcon from "./vectors/NotiIcon";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";
import { getCookie, setCookie } from "../../utils/BrowserHelper";

const NoPhotos = ({
  userEmail,
  registerCustomerLead,
  userOptedNotify
}: {
  userEmail: string | null;
  registerCustomerLead: (email: string) => void;
  userOptedNotify: boolean;
}) => {
  const context = useContext(EventGalleryContext);
  const customerEmailField: any = React.createRef();
  const [submitted, setSubmitted] = useState(false);

  const testEmail = (email: string) => {
    if (email != "" && /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email)) {
      return true;
    } else return false;
  };

  return (
    <div className="egm-nopics egm-noresults">
      <div className="egm-nopics egm-nopics-color">
        <NotiIcon color={context.pageConfiguration.primaryColor} />
        <h4 className="egm-nopics-title">No photos uploaded yet</h4>
        {!userOptedNotify && (
          <Fragment>
            {" "}
            <p className="egm-nopics-para">
              Get notified when photos are uploaded
            </p>
            {submitted ? (
              <div className="collect-customer-email-done">
                Thanks, we'll send you an email once the photos are ready.
              </div>
            ) : (
              <div className="get-notified-input-div">
                {!userEmail && (
                  <input
                    type="text"
                    placeholder="Please enter your email"
                    ref={customerEmailField}
                  />
                )}
                {context.pageConfiguration && (
                  <button
                    className="get-notified-btn"
                    onClick={() =>
                      userEmail
                        ? (registerCustomerLead(userEmail), setSubmitted(true))
                        : (registerCustomerLead(
                            customerEmailField.current.value
                          ),
                          setSubmitted(
                            testEmail(customerEmailField.current.value)
                          ))
                    }
                  >
                    {" "}
                    Notify me{" "}
                  </button>
                )}
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default NoPhotos;

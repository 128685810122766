import React, { useContext } from "react";
import { EventGalleryContext } from "../index";
const noImgIcon = require("../images/dark-no-imgs.svg");
const clearIcon = require("../styles/clear-icon.svg");

const AlbumsIcon = () => {
  const context = useContext(EventGalleryContext);
  return (
    <div className="egm-nopics egm-noresults">
      <div className="egm-ht egm-password">
        <img src={noImgIcon} alt="" />
        <h4 className="egm-nopics-title">
          No photos found under the selected filters
        </h4>
        <button
          className="clear-filters-btn"
          onClick={() => (
            context.setRemoveFilter("clearAllFilters"), context.resetFilter()
          )}
        >
          <img src={clearIcon} alt="" /> Clear filters
        </button>
      </div>
    </div>
  );
};
export default AlbumsIcon;

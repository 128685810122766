import React, { useContext, useState } from "react";
import { Album } from "./types";
import "./styles/albums-list.css";
import AlbumsIcon from "./vectors/AlbumsIcon";
import { EventGalleryContext } from "./index";
const rightArrIcon = require("./styles/right-arrow.svg");
// @ts-ignore
import ahoy from "ahoy.js";

const AlbumsList = ({
  albums,
  toggleAlbum
}: {
  albums: Album[];
  toggleAlbum: (album: Album | null) => void;
}) => {
  const context = useContext(EventGalleryContext);
  const openAlbum = (album: Album) => {
    ahoy.track("albumInteraction", {
      secondary_type: "expandAlbum",
      album_id: album.id,
      event_id: album.eventId
    });
    toggleAlbum(album);
  };
  return (
    <div>
      <div className="egm-albums-title">
        <div>
          <AlbumsIcon color={context.pageConfiguration.primaryColor} />{" "}
          <span>Albums</span>
        </div>
      </div>
      {albums.map(album => (
        <div
          key={album.id}
          className="egm-album-div"
          onClick={() => {
            openAlbum(album);
          }}
        >
          <div>
            <p className="egm-album-name">
              {album.name}
              <button
                className="egm-album-btn"
                onClick={() => openAlbum(album)}
              >
                <img src={rightArrIcon} />
              </button>
            </p>
            <p className="egm-album-count">
              <span className="egm-count">{album.photosCount} photos</span>{" "}
              {!context.hidePhotographersFilter && (
                <>
                  <span>&#8226;&nbsp; by </span>
                  <span className="egm-pgrapher">{album.photographerName}</span>
                </>
              )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AlbumsList;

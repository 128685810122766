import React, { useEffect, useContext, useState, useCallback } from "react";
import { EventGalleryContext } from "./index";
import Telemetry from "../../utils/rudderstackAnalytics";
import LightBoxNavBtns from "./LightBoxNavBtns";

const hiddenIcon = require("./styles/imgHide.svg");
const heartIcon = require("./images/icon-heart-outline.svg");
const likeIcon = require("./images/icon-heart-filled.svg");

const mapImagePosition = {
  NorthEastGravity: {
    top: 0,
    right: 0
  },
  NorthWestGravity: {
    top: 0,
    left: 0
  },
  SouthEastGravity: {
    bottom: 0,
    right: 0
  },
  SouthWestGravity: {
    bottom: 0,
    left: 0
  },
  SouthGravity: {
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0
  }
};

const LightboxImage = ({
  photo,
  hidden,
  setEmailModal,
  liked,
  setLiked,
  handleLikeButtonClick,
  showFavIcon,
  trackGuestFavoriteEmailPrompted,
  hasPrevImage,
  hasNextImage,
  resetLightBoxImage,
  photoIndex
}: {
  photo: any;
  hidden: boolean;
  setEmailModal: (val: boolean) => void;
  liked: boolean;
  setLiked: (val: boolean) => void;
  handleLikeButtonClick: (
    photoId: number,
    userId: number,
    source: string
  ) => void;
  showFavIcon?: boolean;
  trackGuestFavoriteEmailPrompted: (photoId: number, source: string) => void;
  hasPrevImage: boolean;
  hasNextImage: boolean;
  resetLightBoxImage: (index: number) => void;
  photoIndex: number;
}) => {
  const eventGalleryContext = useContext(EventGalleryContext);
  const event = eventGalleryContext.event
    ? eventGalleryContext.event
    : photo.album.event;
  const [overlayImageSize, setOverlayImageSize] = useState(0);
  const startMagicZoomIfDOMReady = () => {
    // @ts-ignore
    if (document.getElementById("lightboxZoomMagnifier")) MagicZoom.start();
    else setTimeout(() => startMagicZoomIfDOMReady());
  };

  useEffect(() => {
    // @ts-ignore
    MagicZoom.update($(".MagicZoom")[0], photo.zoomImg, photo.watermarkedImg);
  }, [startMagicZoomIfDOMReady(), photo]);

  // @ts-ignore
  const imageOnLoadHandler = ({ target: img }) => {
    if (event.overlayImageEnabled)
      setOverlayImageSize((event.overlayImageSize * img.width) / 100);
  };

  const likeUserId =
    eventGalleryContext.userId || eventGalleryContext.guestUserId;

  return (
    <div className="dv-imgdetails">
      {hidden && (
        <span className="geo-visibility-stamp">
          <img src={hiddenIcon} />
        </span>
      )}
      {showFavIcon && (
        <span
          className="geo-heart-stamp"
          onClick={() => {
            likeUserId
              ? (handleLikeButtonClick(photo.id, likeUserId, "lightbox"),
                setLiked(!liked))
              : setEmailModal(true),
              trackGuestFavoriteEmailPrompted(photo.id, "lightbox");
          }}
        >
          <img src={liked ? likeIcon : heartIcon} />
        </span>
      )}

      {event.overlayImageEnabled ? (
        <img
          className="overlay-photo"
          src={event.overlayImageURL}
          style={{
            // @ts-ignore
            ...mapImagePosition[event.overlayImagePosition],
            width: overlayImageSize + "px"
          }}
        />
      ) : null}
      <span className="align-helper" />
      <div>
        <a
          data-zoom-image={photo.zoomImg}
          id="lightboxZoomMagnifier"
          className="MagicZoom"
          data-options="zoomMode: magnifier; zoomDistance: 200; smoothing: true; upscale: true; expand: false; rightClick: false;"
          data-mobile-options="zoomMode: magnifier;"
        >
          <img
            className="lightbox-photo"
            onLoad={imageOnLoadHandler}
            src={photo.watermarkedImg}
          />
        </a>
        <LightBoxNavBtns
          hasPrevImage={hasPrevImage}
          hasNextImage={hasNextImage}
          resetLightBoxImage={resetLightBoxImage}
          photoIndex={photoIndex}
        />
      </div>
    </div>
  );
};

export default LightboxImage;

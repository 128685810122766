import React, { Component, Fragment, Suspense } from "react";
import _ from "lodash"; // TODO: import only whats needed from lodash-es
import Header from "./Header";
import ajax from "superagent";
import GalleryBody from "./GalleryBody";
// @ts-ignore
import PrepaidCustomerAuthPopup from "../BrowseEvent/components/PrepaidCustomerAuthPopup";
// @ts-ignore
import EmbedHeader from "./EmbedHeader";
// @ts-ignore
import StyleOverridesMobile from "./StyleOverridesMobile";
import GetNotifiedPopup from "./GetNotifiedPopup";
import {
  Event,
  Album,
  Cart,
  Photo,
  PrepaidCustomer,
  Photographer,
  PageConfiguration,
  FilterSelections
} from "./types";
import GetNotifiedBanner from "./GetNotifiedBanner";
import NoPhotos from "./NoPhotos";
import PasswordProtected from "./PasswordProtected";
import {
  getCookie,
  setCookie,
  deleteCookie,
  withCartIdParam,
  getParameterByName,
  removeParam
} from "../../utils/BrowserHelper";
import "./styles/index.css";
const gssNewLoader = require("../../assets/gss-loader.gif");
// @ts-ignore
import ahoy from "ahoy.js";
import PrivateScreen from "./PrivateScreen";
import LinkedEventsPopup from "./LinkedEventsPopup";
import ShareSocialPopup from "../ShareSocialPopup/index";
import RemoveBundlePrompt from "./offerBanners/RemoveBundlePrompt";
import PromptClearPrepaidCart from "./offerBanners/PromptClearPrepaidCart";
import BundleMediaSelect from "../BrowseEvent/components/albums/BundleSelectMedia";
import CurationMode from "./CurationMode";
import DeletePhotosConfirmation from "../BrowseEvent/components/DeletePhotosConfirmation";
import PublishBanner from "../PublishBanner";
import PublishingOptions from "../PublishingOptions";
import EventSupportEmailPopup from "./EventSupportEmailPopup";
import Telemetry from "../../utils/rudderstackAnalytics";
import PromptClearCart from "./offerBanners/PromptClearCart";
const clearFIcon = require("./styles/clear-icon.svg");
const Filters = React.lazy(() => import("./Filters/index"));
const OfferBanners = React.lazy(() => import("./offerBanners/index"));
import "./styles/footer-cta.css";
import { LightBoxViewCart } from "./vectors/LightBoxCartIcons";
import ContrastForegroundUtility from "../../utils/ContrastForegroundUtility";
import SaveCart from "../SaveCartEmailPopup";
import TreeView from "../TreeView";
import getAlbumId from "../../utils/GetAlbumId";
import InvitePhotographerPopup from "../common/InvitePhotographerPopup";
const wave = require("../common/images/wave.svg");

interface EventGalleryMobileProps {
  event: Event | any;
  albumsAPI: string;
  addToCartAPI: string;
  removeFromCartAPI: string;
  emptyCartAPI: string;
  registerCustomerLeadAPI: string;
  userSignedIn: boolean;
  userEmail: string | null;
  cart: Cart;
  initialFilters: object;
  currentUserHasPasswordAccess: boolean;
  enableFilterByText: boolean;
  selfieSearchS3Bucket: string;
  embedded: boolean;
  ios: boolean;
  mobile: boolean;
  photoTimeRange: {
    min: number;
    max: number;
  };
  cookieInjectURL: string;
  uploadInProgress: boolean;
  eventAnalyticsEventsAPI: string;
  eventAnalyticsVisitsAPI: string;
  authenticatePrepaidCustomerAPI: string;
  createPrepaidCustomerAPI: string;
  promptPrepaidAuth: boolean | string;
  unlinkPrepaidCustomerAPI: string;
  logoutPrepaidCustomerAPI: string;
  findPhotosPath: string;
  eventPhotosAPI: string;
  galleryPage: string;
  prepaidCustomer: PrepaidCustomer;
  pageConfiguration: PageConfiguration;
  openedAlbumId: string;
  photoBundleDiscountAvailable: boolean;
  availPhotoBundleDiscountAPI: string;
  withdrawPhotoBundleDiscountAPI: string;
  bypassPasswordPrompt: string | null;
  linkedEvents: Event[];
  linkedEventsAvailable: boolean;
  photosAvailable: boolean;
  isAuthorizedForDirectDownloads: boolean;
  manageEventPath: string | null;
  userId: number | null;
  eventPassword: string | null;
  authenticateEventPasswordAPI: string;
  galleryURL: string;
  canManageTags: boolean;
  canDeletePhotos: boolean;
  schoolUser: boolean;
  supportEmail: string;
  eventSupportLabel: string;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  registerGuestUserAPI: string;
  favoritesGuestUserId: number;
  addAllToCartAPI: string;
  hideUploadSelfie: boolean;
  customerCartEmailEnabled: boolean;
  showInvitePhotographerModal: boolean;
  isCustomerOnly: boolean;
}

interface EventGalleryMobileState {
  albums: Album[];
  cart: Cart;
  prepaidCustomer: PrepaidCustomer | null;
  filterSelections: FilterSelections;
  filterOptions: {
    albums: Album[];
    photographers: Photographer[];
    photoTimeRange: { min: number; max: number };
    favorite: boolean;
  };
  defaultFilters: FilterSelections;
  promptPrepaidAuth: boolean | string;
  photosCount: number | null;
  promptNotifyAuth: boolean;
  notifyBanner: boolean;
  loading: boolean;
  authenticated: boolean;
  reset: boolean;
  unlockedPrivateEvent: boolean;
  deletePhotosPopup: boolean;
  discountMessage: string;
  promptRemoveOffer: boolean;
  selfieUploaderPopup: boolean;
  linkedEventsPopup: boolean;
  userInputPassword: string | any;
  showShareGalleryModal: boolean;
  removeSelfie: boolean;
  clearAllFilters: boolean;
  curationMode: boolean;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  showBulkTagging: boolean;
  visiblePhotos: any;
  selectAll: boolean;
  publishPopup: boolean;
  publishState: string;
  selfiePrepaidStatus: string;
  clearPrepaidCartPopup: boolean;
  showEmail: boolean;
  cartFullPrice: string;
  cartDiscountedPrice: string;
  cartHasDiscount: boolean;
  guestUserId: number | null;
  clearCartWarning: boolean;
  albumModeID: string | null;
  showSaveCartModal: boolean;
  isMobile: boolean;
  showInvitePopup: boolean;
}

interface EventGallery {
  addPhotoToCart: (photo: Photo) => void;
  logoutPrepaidCustomer: (availBundleDiscountOffer: boolean) => void;
  removePhotoFromCart: (photo: Photo) => void;
  cart: Cart | any;
  event: Event | any;
  currentUserHasPasswordAccess: any;
  prepaidCustomer: any;
  selfieSearchS3Bucket: string;
  enableBibFilter: boolean;
  filterSelections: FilterSelections;
  promptPrepaidAuth: boolean | string;
  reloadAlbumPhotos: Function;
  activeFilters: string[];
  reset: boolean;
  curatePhotos: Function;
  updatePhotosCount: (photosCount: number) => void;
  photosCount: number | null;
  pageConfiguration: PageConfiguration;
  resetFilter: () => void;
  arePrepaidPhotosAvailable: boolean;
  isEmbed: boolean;
  discountMessage: string;
  photoBundleDiscountAvailable: boolean;
  hidePhotographersFilter: boolean;
  isAuthorizedForDirectDownloads: boolean;
  userId: number | null;
  eventPassword: string | null;
  authenticateEventPasswordAPI: string;
  removeSelfie: boolean;
  clearAllFilters: boolean;
  removeFilter: (val: string, newFilter: object | null) => void;
  setRemoveFilter: (value: string) => void;
  updateFilters: (newFilters: any, clearFilters: any) => void;
  canManageTags: boolean;
  clearMediaIds: () => void;
  getPhotoId: (photo: Photo, index: any, photos: any) => void;
  listOfMediaId: Array<{ id: number; hidden: boolean }>;
  curationMode: boolean;
  setPhotos: Function;
  setSelectAll: Function;
  promptClearPrepaidCart: () => void;
  showPrepaidClearCartWarning: boolean;
  supportEmail: string;
  eventSupportLabel: string;
  cartFullPrice: string;
  cartDiscountedPrice: string;
  cartHasDiscount: boolean;
  addPhotoToFavoritesAPI: string;
  removePhotoFromFavoritesAPI: string;
  registerGuestUserAPI: string;
  guestUserId: number | null;
  setGuestUserId: (val: number) => void;
  hideUploadSelfie: boolean;
  setClearCartWarning: (val: boolean) => void;
  albumModeID: string | null;
  showInvitePhotographerModal: boolean;
  showGalleryActions: Function;
}

export const EventGalleryContext = React.createContext<EventGallery>({
  addPhotoToCart: () => null,
  logoutPrepaidCustomer: () => null,
  removePhotoFromCart: () => null,
  cart: null,
  event: null,
  currentUserHasPasswordAccess: null,
  prepaidCustomer: null,
  selfieSearchS3Bucket: "",
  enableBibFilter: false,
  reset: false,
  curatePhotos: (action?: string, photoId?: number[], render?: boolean) => null,
  reloadAlbumPhotos: (val: boolean) => null,
  filterSelections: {
    albums: [],
    photographers: [],
    photoTime: {
      minuteFrom: 0, // 00:00 to minutes
      minuteTo: 1439, // 23:59 to minutes
      active: false
    },
    referenceImage: null,
    photoText: null,
    favorite: false
  },
  promptPrepaidAuth: getParameterByName("prepaid-auth") || false,
  activeFilters: [],
  updatePhotosCount: () => null,
  setPhotos: () => null,
  photosCount: null,
  pageConfiguration: {
    primaryColor: "#259FA5",
    secondaryColor: "#C1EBED",
    accentColor: "#259FA5",
    showHeader: false,
    bannerUrl: "",
    croppedBannerURL: ""
  },
  updateFilters: (newFilters: any, clearFilters: any) => null,
  resetFilter: () => null,
  arePrepaidPhotosAvailable: false,
  isEmbed: false,
  photoBundleDiscountAvailable: false,
  discountMessage: "",
  hidePhotographersFilter: false,
  isAuthorizedForDirectDownloads: false,
  userId: null,
  eventPassword: "",
  authenticateEventPasswordAPI: "",
  removeFilter: (val: string, newFilter: object | null) => null,
  setRemoveFilter: (value: string) => null,
  removeSelfie: false,
  clearAllFilters: false,
  canManageTags: false,
  clearMediaIds: () => null,
  getPhotoId: (photo: Photo, index: any, photos: any) => [],
  listOfMediaId: [],
  curationMode: false,
  setSelectAll: () => null,
  promptClearPrepaidCart: () => null,
  showPrepaidClearCartWarning: false,
  supportEmail: "",
  eventSupportLabel: "",
  cartFullPrice: "",
  cartDiscountedPrice: "",
  cartHasDiscount: false,
  addPhotoToFavoritesAPI: "",
  removePhotoFromFavoritesAPI: "",
  registerGuestUserAPI: "",
  guestUserId: null,
  setGuestUserId: (val: number) => null,
  hideUploadSelfie: false,
  setClearCartWarning: (val: boolean) => null,
  albumModeID: null,
  showInvitePhotographerModal: false,
  showGalleryActions: () => false
});

export const EventGalleryContextConsumer = EventGalleryContext.Consumer;

class EventGalleryMobile extends Component<
  EventGalleryMobileProps,
  EventGalleryMobileState
> {
  constructor(props: EventGalleryMobileProps) {
    super(props);
    const initialDefaultFilters: FilterSelections = {
      albums: [],
      photographers: [],
      photoTime: {
        minuteFrom: props.photoTimeRange.min || 0, // 00:00 to minutes
        minuteTo: props.photoTimeRange.max || 1439, // 23:59 to minutes
        active: false
      },
      referenceImage: null,
      photoText: null,
      favorite: false
    };

    this.state = {
      loading: true,
      reset: false,
      albums: [],
      cart: this.props.cart,
      deletePhotosPopup: false,
      prepaidCustomer: this.props.prepaidCustomer,
      filterSelections: {
        ...initialDefaultFilters,
        ...props.initialFilters,
        referenceImage: this.getReferenceImage()
      },
      showShareGalleryModal: false,
      publishPopup: false,
      publishState: this.props.event.publishMode,
      filterOptions: {
        albums: [],
        photographers: [],
        photoTimeRange: props.photoTimeRange,
        favorite: false
      },
      discountMessage: "",
      defaultFilters: initialDefaultFilters,
      promptPrepaidAuth:
        getParameterByName("prepaid-auth") ||
        (this.props.event.prepaidCustomersEnabled &&
          !this.props.prepaidCustomer &&
          this.props.promptPrepaidAuth),
      photosCount: null,
      promptNotifyAuth: false,
      notifyBanner: this.props.uploadInProgress,
      authenticated: false,
      unlockedPrivateEvent: this.props.event.isPrivate
        ? !!this.getReferenceImage()
        : true,
      promptRemoveOffer: false,
      selfieUploaderPopup: false,
      linkedEventsPopup: false,
      userInputPassword: "",
      removeSelfie: false,
      clearAllFilters: false,
      curationMode: false,
      listOfMediaId: [],
      showBulkTagging: false,
      visiblePhotos: [],
      selectAll: false,
      selfiePrepaidStatus: this.defaultSelfiePrepaidStatus(),
      clearPrepaidCartPopup: false,
      showEmail: false,
      guestUserId: this.props.favoritesGuestUserId,
      clearCartWarning: false,
      albumModeID: null,
      showSaveCartModal: false,
      isMobile: window.innerWidth < 992,
      showInvitePopup: false
    };
    ahoy.configure({
      eventsUrl: props.eventAnalyticsEventsAPI,
      visitsUrl: props.eventAnalyticsVisitsAPI,
      trackVisits: true
    });
    ahoy.trackView({ event_id: props.event.id });

    this.addPhotoToCart = this.addPhotoToCart.bind(this);
    this.logoutPrepaidCustomer = this.logoutPrepaidCustomer.bind(this);
    this.removePhotoFromCart = this.removePhotoFromCart.bind(this);
    this.emptyPhotosFromCart = this.emptyPhotosFromCart.bind(this);
    this.removePhotoBundleDiscount = this.removePhotoBundleDiscount.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.activeFilters = this.activeFilters.bind(this);
    this.updatePhotosCount = this.updatePhotosCount.bind(this);
    this.registerCustomerLead = this.registerCustomerLead.bind(this);
    this.userOptedNotify = this.userOptedNotify.bind(this);
    this.navigateToPreviousURL = this.navigateToPreviousURL.bind(this);
    this.unlockProtectedEvent = this.unlockProtectedEvent.bind(this);
    this.setSelfieUploaderPopup = this.setSelfieUploaderPopup.bind(this);
    this.defaultSelfiePrepaidStatus =
      this.defaultSelfiePrepaidStatus.bind(this);
  }

  public componentDidMount() {
    const { event } = this.props;

    this.setState({ albumModeID: getAlbumId() });

    if ((getAlbumId() || !event.isProtected) && this.state.isMobile) {
      this.loadAlbums();
    } else {
      this.authenticateEvent();
    }

    this.checkDiscountAndGetStatus();
    $("body").bind("copy", e => {
      e.preventDefault();
      return false;
    });
    $("body").bind("paste", e => {
      e.preventDefault();
      return false;
    });
    if (this.showGalleryActions() && this.props.showInvitePhotographerModal) {
      Telemetry.track("internal-referrals:event-gallery-ph-referral-btn-shown");
    }
    if (this.showGalleryActions() && this.props.canManageTags) {
      Telemetry.track("curate-btn-shown");
    }
  }

  componentDidUpdate(
    prevProps: Readonly<EventGalleryMobileProps>,
    prevState: Readonly<EventGalleryMobileState>
  ): void {
    if (
      prevState.authenticated !== this.state.authenticated &&
      this.state.authenticated
    ) {
      this.setState({ loading: true });
      this.loadAlbums();
    }
  }

  removeFilter = (val: string, newFilter: object | null) =>
    this.setState(
      //@ts-ignore
      { [val]: false },
      () =>
        this.updateFilters(newFilter, val === "clearAllFilters" ? true : false)
    );

  //@ts-ignore
  setRemoveFilter = (value: string) => this.setState({ [value]: true });

  clearMediaIds = () => this.setState({ listOfMediaId: [] });

  defaultSelfiePrepaidStatus = () => {
    if (this.props.event.prepaidCustomersPrimaryAuthField == "selfie") {
      if (this.props.prepaidCustomer) return "available";
    }
    return "unavailable";
  };

  getPhotoId = (photo: Photo, index: any, photos: any) => {
    const listofIDs = [...this.state.listOfMediaId];
    const listOfMedia = [...photos];
    listOfMedia[index].isSelected = true;
    const arrayOfId = listofIDs.filter(i => i.id === photo.id);
    if (arrayOfId.length === 0) {
      listofIDs.push({ id: photo.id, hidden: photo.hidden });
      this.setState({ listOfMediaId: listofIDs });
    } else {
      let indexOfId = -1;
      listofIDs.forEach((i, index) => {
        if (i.id === photo.id) {
          indexOfId = index;
        }
      });
      listOfMedia[index].isSelected = false;
      listofIDs.splice(indexOfId, 1);
      this.setState({ listOfMediaId: listofIDs });
    }
  };

  selectAllVisible = () => {
    let listofIDs: any[] = [];
    if (!this.state.selectAll) {
      this.state.visiblePhotos.forEach((element: any) => {
        element.isSelected = true;
        listofIDs.push({ id: element.id, hidden: element.hidden });
      });
    } else {
      this.state.visiblePhotos.forEach((element: any) => {
        element.isSelected = false;
      });
      listofIDs = [];
    }

    this.setState({
      listOfMediaId: listofIDs,
      selectAll: !this.state.selectAll
    });
  };
  setPhotos = (val: any) =>
    this.setState({
      visiblePhotos: val
    });
  setSelectAll = () => this.setState({ selectAll: false });

  deletePhotos = async () => {
    const { listOfMediaId } = this.state;
    const arrayOfIds = listOfMediaId.map(item => item.id);
    try {
      const res = await $.ajax({
        url: `/api/v1/photos/delete`,
        type: "DELETE",
        data: {
          photo_ids: arrayOfIds,
          event_id: this.props.event.id
        }
      });

      //@ts-ignore
      toastr.success("Successfully updated");
      Telemetry.track("event-gallery-curate:photos-deleted");
      this.setState({
        listOfMediaId: [],
        selectAll: false,
        reset: true,
        deletePhotosPopup: false
      });
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update");
      console.error(err);
    }
  };

  curatePhotos = async () => {
    const { listOfMediaId } = this.state;
    const arrayOfIds = listOfMediaId.map(item => item.id);
    const actionVal = this.hideOrUnhideAction();
    try {
      const res = await $.ajax({
        url: `/api/v1/photos/${actionVal}`,
        type: "POST",
        data: {
          photo_ids: arrayOfIds,
          event_id: this.props.event.id
        }
      });

      //@ts-ignore
      toastr.success("Successfully updated");
      Telemetry.track(`event-gallery-curate:${actionVal}-photos`);
      this.setState({
        listOfMediaId: [],
        selectAll: false,
        reset: true
      });
    } catch (err) {
      //@ts-ignore
      toastr.error("Failed to update");
      console.error(err);
    }
  };

  hideOrUnhideAction = () => {
    if (this.state.listOfMediaId.length === 0) return "hide";
    const hideOrUnhide = this.state.listOfMediaId.filter(i =>
      i.hidden != true ? true : false
    );
    return hideOrUnhide.length > 0 ? "hide" : "unhide";
  };

  reloadAlbumPhotos = (val: boolean) => this.setState({ reset: val });

  showAddAllToCartBtn = () =>
    this.state.filterSelections.favorite &&
    !this.state.loading &&
    !this.state.curationMode &&
    this.state.visiblePhotos.length > 0;

  showGalleryActions = () => {
    return (
      (!this.showPasswordComponent() && !this.props.event.isPrivate) ||
      ((this.state.unlockedPrivateEvent || this.getReferenceImage()) &&
        !this.showPasswordComponent())
    );
  };

  clearFilters = (e: any) => (e.preventDefault(), this.resetFilter());
  closeSaveCartModal = () => this.setState({ showSaveCartModal: false });

  setClearCartWarning = (val: boolean) =>
    this.setState({ clearCartWarning: val });

  photoBundleDiscountApplied = () =>
    this.props.cart &&
    this.props.cart.photoBundleDiscountAvailed &&
    this.props.event.id == this.props.cart.photoBundleDiscountEventId;

  clearCart = () => {
    this.state.filterSelections.referenceImage
      ? this.availPhotoBundleDiscount()
      : this.emptyPhotosFromCart();
    !this.state.filterSelections.referenceImage ||
    (this.state.filterSelections.referenceImage &&
      this.photoBundleDiscountApplied())
      ? this.setSelfieUploaderPopup(true)
      : null;
  };
  canShowFooterCTA = () =>
    this.state.cart?.photos?.length && !this.props.embedded;

  setSaveCartPromptShown = () =>
    localStorage.setItem("isUserAskedForEmailToSaveCart", "true");

  setGuestUser = (userId: number) => this.setState({ guestUserId: userId });

  trackCartEmailPopupActions = (eventName: string) => {
    Telemetry.track(eventName, {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      cartId: this.state.cart.id
    });
  };

  shouldShowClearFilters = () => {
    if (this.state.albumModeID) {
      return (
        this.activeFilters().includes("albums") &&
        this.activeFilters().length > 1
      );
    }

    return !this.state.albumModeID;
  };

  public render() {
    return (
      <div>
        <EventGalleryContext.Provider
          value={{
            addPhotoToCart: this.addPhotoToCart,
            logoutPrepaidCustomer: this.logoutPrepaidCustomer,
            removePhotoFromCart: this.removePhotoFromCart,
            cart: this.state.cart,
            event: this.props.event,
            currentUserHasPasswordAccess:
              this.props.currentUserHasPasswordAccess,
            reloadAlbumPhotos: this.reloadAlbumPhotos,
            prepaidCustomer: this.props.prepaidCustomer,
            addPhotoToFavoritesAPI: this.props.addPhotoToFavoritesAPI,
            removePhotoFromFavoritesAPI: this.props.removePhotoFromFavoritesAPI,
            registerGuestUserAPI: this.props.registerGuestUserAPI,
            selfieSearchS3Bucket: this.props.selfieSearchS3Bucket,
            enableBibFilter: this.props.enableFilterByText,
            filterSelections: this.state.filterSelections,
            promptPrepaidAuth: this.props.promptPrepaidAuth,
            activeFilters: this.activeFilters(),
            updatePhotosCount: this.updatePhotosCount,
            curatePhotos: this.curatePhotos,
            reset: this.state.reset,
            photosCount: this.state.photosCount,
            pageConfiguration: this.props.pageConfiguration,
            resetFilter: this.resetFilter,
            arePrepaidPhotosAvailable: this.arePrepaidPhotosAvailable(),
            discountMessage: this.state.discountMessage,
            cartFullPrice: this.state.cartFullPrice,
            cartDiscountedPrice: this.state.cartDiscountedPrice,
            cartHasDiscount: this.state.cartHasDiscount,
            isEmbed: this.props.embedded,
            photoBundleDiscountAvailable:
              this.props.photoBundleDiscountAvailable,
            hidePhotographersFilter: this.props.event.hidePhotographersFilter,
            isAuthorizedForDirectDownloads:
              this.props.isAuthorizedForDirectDownloads,
            userId: this.props.userId,
            eventPassword: this.props.eventPassword,
            authenticateEventPasswordAPI:
              this.props.authenticateEventPasswordAPI,
            removeSelfie: this.state.removeSelfie,
            clearAllFilters: this.state.clearAllFilters,
            removeFilter: this.removeFilter,
            setRemoveFilter: this.setRemoveFilter,
            canManageTags: this.props.canManageTags,
            clearMediaIds: this.clearMediaIds,
            getPhotoId: this.getPhotoId,
            updateFilters: this.updateFilters,
            listOfMediaId: this.state.listOfMediaId,
            curationMode: this.state.curationMode,
            setPhotos: this.setPhotos,
            setSelectAll: () => this.setSelectAll(),
            promptClearPrepaidCart: this.promptClearPrepaidCart,
            showPrepaidClearCartWarning: this.showPrepaidClearCartWarning(),
            setClearCartWarning: (val: boolean) =>
              this.setClearCartWarning(val),
            eventSupportLabel: this.props.event.eventSupportLabel,
            supportEmail: this.props.event.supportEmail,
            guestUserId: this.state.guestUserId,
            setGuestUserId: (val: number) =>
              this.setState({ guestUserId: val }),
            hideUploadSelfie: this.props.hideUploadSelfie,
            albumModeID: this.state.albumModeID,
            showInvitePhotographerModal: this.props.showInvitePhotographerModal,
            showGalleryActions: () => this.showGalleryActions()
          }}
        >
          <div className="egm-wrap">
            {this.state.publishState === "unpublished" &&
              this.props.event.canUpdatePrivacy && (
                <PublishBanner
                  openPublishPopup={() => this.setState({ publishPopup: true })}
                />
              )}
            {this.props.embedded ? (
              <EmbedHeader
                cart={this.state.cart}
                isEmbed={this.props.embedded}
                primaryColor={this.props.pageConfiguration.primaryColor}
              />
            ) : null}
            <StyleOverridesMobile
              primaryColor={this.props.pageConfiguration.primaryColor}
              secondaryColor={this.props.pageConfiguration.secondaryColor}
              accentColor={this.props.pageConfiguration.accentColor}
            />
            {this.props.userSignedIn &&
              !this.props.isCustomerOnly &&
              this.state.isMobile && (
                <div
                  className="egm-head-wrapper"
                  style={{ margin: "-20px -15px 20px" }}
                >
                  <TreeView
                    currentEventId={this.props.event.id}
                    albumObject={
                      this.state.albums.length > 0 ? this.state.albums[0] : null
                    }
                  />
                </div>
              )}
            <Header
              event={this.props.event}
              albumName={
                this.state.albums.length > 0 ? this.state.albums[0].name : ""
              }
              navigateToPreviousURL={this.navigateToPreviousURL}
              isExpanded={false}
              linkedEventsAvailable={this.props.linkedEventsAvailable}
              promptLinkedEventsPopup={() =>
                this.setState({ linkedEventsPopup: true })
              }
              showShareGallery={() =>
                this.setState({ showShareGalleryModal: true })
              }
              albumModeID={this.state.albumModeID}
            />
            {this.props.photosAvailable && (
              <Fragment>
                {this.renderOfferBanners()}
                {this.props.event.supportEmail ? (
                  <div className="geo-email-support-div">
                    {this.state.showEmail && (
                      <EventSupportEmailPopup
                        showEmail={this.state.showEmail}
                        customerSupportReference={
                          this.props.event.eventSupportLabel
                        }
                        supportEmail={this.props.event.supportEmail}
                      />
                    )}
                    <div className="contact-refer-div">
                      <span
                        style={{
                          color: this.props.pageConfiguration.primaryColor,
                          textDecoration: `1px underline ${this.props.pageConfiguration.primaryColor}`
                        }}
                        onClick={() =>
                          this.setState({ showEmail: !this.state.showEmail })
                        }
                      >
                        {this.props.event.eventSupportLabel
                          ? this.props.event.eventSupportLabel
                          : "Contact support"}
                      </span>
                      {this.props.showInvitePhotographerModal && (
                        <div
                          className="invite-ph-box"
                          onClick={() => {
                            Telemetry.track(
                              "internal-referrals:event-gallery-ph-referral"
                            );
                            this.setState({ showInvitePopup: true });
                          }}
                        >
                          <img src={wave} />
                          <div>
                            {" "}
                            Are you a <br />
                            photographer?
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  this.props.showInvitePhotographerModal && (
                    <div
                      className="geo-email-support-div"
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <div
                        className="invite-ph-box"
                        onClick={() => {
                          Telemetry.track(
                            "internal-referrals:event-gallery-ph-referral"
                          );
                          this.setState({ showInvitePopup: true });
                        }}
                      >
                        <img src={wave} />
                        <div>
                          {" "}
                          Are you a <br />
                          photographer?
                        </div>
                      </div>
                    </div>
                  )
                )}
                {this.showGalleryActions() && (
                  <>
                    <div className="geo-egm__tag-tog">
                      <div>
                        {this.props.manageEventPath && (
                          <a
                            href={this.props.manageEventPath}
                            target="_blank"
                            className="geo-eg__link-mobile"
                          >
                            Manage Event
                          </a>
                        )}
                      </div>

                      {this.props.canManageTags && (
                        <div className="tag-mode-tog">
                          {" "}
                          <span
                            className="geo-form__togtext"
                            style={{ marginRight: 5 }}
                          >
                            Curate
                          </span>
                          <label className="switch geo-form__toggle">
                            <input
                              type="checkbox"
                              className="geo-form__toginput"
                              onChange={() =>
                                this.setState(
                                  { curationMode: !this.state.curationMode },
                                  () =>
                                    this.state.curationMode
                                      ? this.emptyPhotosFromCart()
                                      : this.clearMediaIds()
                                )
                              }
                            />
                            <span
                              className="geo-form__tog"
                              style={
                                this.state.curationMode
                                  ? {
                                      background:
                                        this.props.pageConfiguration
                                          .primaryColor
                                    }
                                  : {}
                              }
                            >
                              <span className="geo-form__tog-in"></span>
                            </span>
                          </label>
                        </div>
                      )}
                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Filters
                        filterSelections={this.state.filterSelections}
                        filterOptions={this.state.filterOptions}
                        updateFilters={this.updateFilters}
                        photoTimeRange={this.props.photoTimeRange}
                        setBundleRemovePrompt={() =>
                          this.setState({ promptRemoveOffer: true })
                        }
                      />
                    </Suspense>
                  </>
                )}

                {this.renderNotifyBanner()}
                {this.activeFilters().length && this.state.photosCount ? (
                  <div className="egm-filter-container-2">
                    <div className="egm-filter-row-2">
                      <p className="egm-filter-count-p">
                        {this.state.photosCount} images found
                      </p>
                      {this.shouldShowClearFilters() && (
                        <button
                          className="egm-filter-clear-btn"
                          onClick={e => this.clearFilters(e)}
                        >
                          <img src={clearFIcon} /> Clear
                        </button>
                      )}
                    </div>
                  </div>
                ) : null}
                {this.showGalleryActions() && this.showAddAllToCartBtn() && (
                  <div>
                    <button
                      className="geo-btn geo-btn--secondary-ghost geo-em__btn geo-addAll-btn"
                      onClick={() => {
                        Telemetry.track("event-gallery:add-all-to-cart", {
                          eventId: this.props.event.id,
                          eventName: this.props.event.name,
                          cartId: this.props.cart.id
                        });
                        this.addAllToCart(this.state.visiblePhotos);
                      }}
                      style={{ marginBottom: 10 }}
                    >
                      {" "}
                      Add all to cart
                    </button>
                  </div>
                )}
              </Fragment>
            )}
            {this.showGalleryActions() && (
              <CurationMode
                activeFilters={this.activeFilters}
                curationMode={this.state.curationMode}
                confirmDelete={() => this.setState({ deletePhotosPopup: true })}
                curatePhotos={this.curatePhotos}
                selectAll={this.state.selectAll}
                selectAllVisible={this.selectAllVisible}
                listOfMediaId={this.state.listOfMediaId}
                setBulkTagging={() => this.setState({ showBulkTagging: true })}
                canDeletePhotos={this.props.canDeletePhotos}
                pageConfiguration={this.props.pageConfiguration}
                hideOrUnhideAction={this.hideOrUnhideAction}
              />
            )}

            {this.renderBody()}
            {this.renderPopups()}
          </div>
        </EventGalleryContext.Provider>
        {this.state.showShareGalleryModal ? (
          <ShareSocialPopup
            galleryURL={this.props.galleryURL}
            hideShareGalleryModal={() =>
              this.setState({ showShareGalleryModal: false })
            }
            text={"Share with participants or just show off ;)"}
          />
        ) : null}
        {this.state.promptRemoveOffer && (
          <RemoveBundlePrompt
            setPromptRemoveOffer={(val = true) =>
              this.setState({ promptRemoveOffer: val })
            }
            removePhotoBundleDiscount={this.removePhotoBundleDiscount}
            hideBundleRemovePrompt={() =>
              this.setState({ promptRemoveOffer: false })
            }
            removeSelfie={this.state.removeSelfie}
            clearAllFilters={this.state.clearAllFilters}
            removeFilter={this.removeFilter}
          />
        )}
        {this.state.showBulkTagging && (
          <BundleMediaSelect
            listOfMediaId={this.state.listOfMediaId}
            mediaCount={this.state.listOfMediaId.length}
            appliedTags={[]}
            contentTagsAPI={"/api/v1/content_tags"}
            handleClose={() =>
              this.setState({ showBulkTagging: false, selectAll: false })
            }
            handlePostCall={() => (
              this.clearMediaIds(), this.setState({ listOfMediaId: [] })
            )}
          />
        )}
        {this.state.deletePhotosPopup && (
          <DeletePhotosConfirmation
            closePopup={() => this.setState({ deletePhotosPopup: false })}
            deletePhotos={this.deletePhotos}
          />
        )}
        {this.state.clearCartWarning && (
          <PromptClearCart
            clearCart={this.clearCart}
            setClearCartWarning={this.setClearCartWarning}
            photoBundleDiscountAvailed={
              this.state.cart
                ? this.state.cart.photoBundleDiscountAvailed
                : false
            }
          />
        )}

        {this.canShowFooterCTA() ? (
          <a
            href={withCartIdParam(
              "/checkout?source=event-gallery",
              this.props.cart ? this.props.cart.id : null,
              this.props.embedded
            )}
            className="geo-btn geo-btn--caps geo-btn--primary egm-view-cart-btn"
          >
            <LightBoxViewCart
              color={ContrastForegroundUtility.fgColor(
                this.props.pageConfiguration.primaryColor
              )}
            />{" "}
            View Cart
          </a>
        ) : null}
        {this.state.showSaveCartModal && (
          <SaveCart
            closeSaveCartModal={this.closeSaveCartModal}
            cartId={this.state.cart.id}
            setSaveCartPromptShown={this.setSaveCartPromptShown}
            setGuestUser={this.setGuestUser}
            trackCartEmailPopupActions={this.trackCartEmailPopupActions}
          />
        )}
      </div>
    );
  }
  private prepaidAuthWithNoSelfie() {
    return (
      this.props.event.prepaidCustomersEnabled &&
      this.props.event.prepaidCustomersPrimaryAuthField == "selfie" &&
      (this.state.selfiePrepaidStatus == "unavailable" ||
        !this.state.filterSelections.referenceImage)
    );
  }

  prepaidAuthSelfie =
    this.props.event.prepaidCustomersEnabled &&
    this.props.event.prepaidCustomersPrimaryAuthField == "selfie";

  private renderOfferBanners() {
    return (
      (this.props.event.prepaidCustomersEnabled || this.hasValidDiscount()) &&
      (this.prepaidAuthWithNoSelfie() ? null : (
        <Suspense fallback={<div>Loading...</div>}>
          <OfferBanners
            filterSelections={this.state.filterSelections}
            prepaidCustomer={this.state.prepaidCustomer}
            logoutPrepaidCustomer={this.logoutPrepaidCustomer}
            promptPrepaidCustomerAuth={() =>
              this.setState({ promptPrepaidAuth: true })
            }
            updateFilters={this.updateFilters}
            prepaidHardLimitEnabled={
              this.props.event.prepaidCustomersHardLimitEnabled
            }
            cartCount={
              this.state.cart && this.state.cart.photos
                ? this.state.cart.photos.length
                : 0
            }
            emptyPhotosFromCart={this.emptyPhotosFromCart}
            removePhotoBundleDiscount={this.removePhotoBundleDiscount}
            offerRemover={this.state.promptRemoveOffer}
            hideBundleRemovePrompt={() =>
              this.setState({ promptRemoveOffer: false })
            }
            setSelfieUploaderPopup={this.setSelfieUploaderPopup}
            selfieUploaderPopup={this.state.selfieUploaderPopup}
            getReferenceImage={this.getReferenceImage}
            availPhotoBundleDiscount={this.availPhotoBundleDiscount}
            clearCartWarning={this.state.clearCartWarning}
            setClearCartWarning={this.setClearCartWarning}
          />
        </Suspense>
      ))
    );
  }

  private renderNotifyBanner() {
    return (
      this.state.notifyBanner &&
      !this.userOptedNotify() && (
        <GetNotifiedBanner
          userEmail={this.props.userEmail}
          registerCustomerLead={this.registerCustomerLead}
          getNotifiedPopup={() => {
            this.setState({ promptNotifyAuth: true });
          }}
          photoBundleDiscountAvailed={
            this.state.cart ? this.state.cart.photoBundleDiscountAvailed : false
          }
          closeBanner={() => this.setState({ notifyBanner: false })}
        />
      )
    );
  }

  trackPrivateEventSelfieUploadFlow = (trackEventName: string) => {
    Telemetry.track(trackEventName, {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      eoUserId: this.props.event.eoUserId
    });
  };

  trackSelfieFilterApplied = () => {
    Telemetry.track("event-gallery:filter-applied", {
      eventId: this.props.event.id,
      eventName: this.props.event.name,
      filterType: "selfie",
      embeddedMode: this.props.embedded
    });
  };

  private renderBody() {
    return this.state.loading ? (
      <div className="egm-loader gss-loader" style={{ clear: "both" }}>
        <img className="photos-date-img" src={gssNewLoader} />
      </div>
    ) : !this.props.photosAvailable ? (
      <NoPhotos
        userEmail={this.props.userEmail}
        registerCustomerLead={this.registerCustomerLead}
        userOptedNotify={this.userOptedNotify()}
      />
    ) : this.showPasswordComponent() ? (
      <PasswordProtected
        event={this.props.event}
        updateUserInputPassword={password =>
          this.setState({ userInputPassword: password })
        }
        unlockEvent={() => this.unlockProtectedEvent()}
      />
    ) : !this.state.unlockedPrivateEvent ? (
      <PrivateScreen
        updateFilters={this.updateFilters}
        trackPrivateEventSelfieUploadFlow={
          this.trackPrivateEventSelfieUploadFlow
        }
        trackSelfieFilterApplied={this.trackSelfieFilterApplied}
      />
    ) : this.state.albums.length > 0 ? (
      <GalleryBody
        listOfMediaId={this.state.listOfMediaId}
        userId={this.props.userId}
        albums={this.state.albums}
        eventPhotosAPI={this.props.eventPhotosAPI}
        openedAlbum={
          this.props.openedAlbumId
            ? this.findAlbumById(this.props.openedAlbumId)
            : null
        }
        userInputPassword={this.state.userInputPassword}
      />
    ) : null;
  }

  private renderPopups() {
    return (
      <Fragment>
        {this.state.promptNotifyAuth && (
          <GetNotifiedPopup
            event={this.props.event}
            registerCustomerLead={this.registerCustomerLead}
            clearSelection={() => {
              this.setState({ promptNotifyAuth: false });
            }}
          />
        )}

        {this.state.promptPrepaidAuth && (
          <PrepaidCustomerAuthPopup
            event={this.props.event}
            authenticatePrepaidCustomerAPI={
              this.props.authenticatePrepaidCustomerAPI
            }
            setPrepaidCustomer={(prepaidCustomer: PrepaidCustomer) => {
              this.setState({
                prepaidCustomer,
                promptPrepaidAuth: false
              });
            }}
            setUpdatedCart={(updatedCart: Cart) => {
              this.setState({ cart: updatedCart });
            }}
            cancelPrepaidAuth={() =>
              this.setState({ promptPrepaidAuth: false }, () => {
                removeParam("prepaid-auth", window.location.href),
                  window.scrollTo(0, 0);
              })
            }
            cartId={this.state.cart ? this.state.cart.id : null}
          />
        )}

        {this.state.linkedEventsPopup && this.props.linkedEventsAvailable && (
          <LinkedEventsPopup
            linkedEvents={this.props.linkedEvents}
            closeLinkedEventsPopup={() =>
              this.setState({ linkedEventsPopup: false })
            }
          />
        )}
        {this.state.publishPopup && (
          <PublishingOptions
            closePopup={() => this.setState({ publishPopup: false })}
            updateEventPrivacy={(id: number, val: { publish_mode: string }) =>
              this.setState({ publishState: val.publish_mode })
            }
            schoolUser={this.props.schoolUser}
            eventData={{
              id: this.props.event.id,
              name: this.props.event.name,
              url: this.props.galleryURL,
              publishMode: this.props.event.publishMode,
              password: ""
            }}
          />
        )}

        {this.state.clearPrepaidCartPopup && (
          <PromptClearPrepaidCart
            closePopup={() => this.setState({ clearPrepaidCartPopup: false })}
            clearCart={() => {
              this.emptyPhotosFromPrepaidCart();
            }}
          />
        )}
        {this.state.showInvitePopup && (
          <InvitePhotographerPopup
            handleCloseClick={() => this.setState({ showInvitePopup: false })}
            type={"mobile"}
          />
        )}
      </Fragment>
    );
  }

  private showPasswordComponent = () =>
    this.props.event.isProtected && !this.state.authenticated;

  private hasValidDiscount = () =>
    this.props.event.price !== 0 && this.props.event.hasDiscount;

  private arePrepaidPhotosAvailable() {
    return !!(
      this.state.cart &&
      this.state.prepaidCustomer &&
      (!this.props.event.prepaidCustomersHardLimitEnabled ||
        this.state.cart.prepaidPhotos.length <
          this.state.prepaidCustomer.freePhotosAvailable)
    );
  }

  private showPrepaidClearCartWarning() {
    return (
      this.prepaidAuthSelfie &&
      this.state.cart &&
      this.state.cart.photos.length > 0
    );
  }

  private promptClearPrepaidCart = () => {
    this.setState({
      clearPrepaidCartPopup: true
    });
  };

  private bundleDiscountAvailedFromAnotherEvent = (photo: Photo) =>
    this.state.cart &&
    this.state.cart.photoBundleDiscountAvailed &&
    photo.album.eventId != this.state.cart.photoBundleDiscountEventId;

  private addAllToCart = async (valArray: Array<Photo>) => {
    const photoIds: any[] = [];
    valArray.map((i: { id: any }) => photoIds.push(i.id));
    if (
      this.props.cart &&
      this.props.event.currency !== this.props.cart.currency &&
      this.props.cart.photos.length > 0
    ) {
      // @ts-ignore
      toastr.error(
        `The cart can only contain photos in one currency, currently ${this.props.event.currency}. Clear and try adding again.`
      );
    } else if (
      this.bundleDiscountAvailedFromAnotherEvent(valArray[0]) &&
      this.props.cart &&
      this.props.cart.photos.length > 0
    ) {
      // @ts-ignore
      toastr.error(
        `You have an active package discount applied. This feature does not permit photos to be added from multiple events. Please clear cart or remove offer before proceeding.`,
        { timeOut: 9500 }
      );
    } else if (
      this.state.prepaidCustomer &&
      this.props.event.id !== this.state.prepaidCustomer.eventId
    ) {
      // @ts-ignore
      toastr.error(
        "Your photo pack only applies to the event you purchased it for."
      );
    } else {
      await ajax
        .post(this.props.addAllToCartAPI)
        .send({
          photo_ids: photoIds,
          activeFilters: this.activeFilters(),
          cart_id: this.state.cart ? this.state.cart.id : null
        })
        .then(res => {
          this.setState(
            {
              cart: res.body.cart
            },
            () => {
              // @ts-ignore
              toastr.success("Added to cart", null, { timeOut: 1000 });
              this.checkDiscountAndGetStatus();
              $.getScript(
                withCartIdParam("/cart_sales_count", this.state.cart.id)
              );
            }
          );
        })
        .catch(err => {
          console.log(err);
        });
      if (
        this.bundleDiscountAvailedFromAnotherEvent(this.state.visiblePhotos[0])
      ) {
        this.setState(
          {
            cart: {
              ...this.state.cart,
              photoBundleDiscountAvailed: false
            }
          },
          () => this.removePhotoBundleDiscount(false, false)
        );
      }
      this.setState({
        cart: {
          ...this.state.cart,
          photos: _.concat(
            this.state.cart ? this.state.cart.photos : [],
            this.state.visiblePhotos
          ),
          currency: this.props.event.currency,
          prepaidPhotos: this.arePrepaidPhotosAvailable()
            ? _.concat(this.state.cart.prepaidPhotos, this.state.visiblePhotos)
            : this.state.cart
            ? this.state.cart.prepaidPhotos
            : []
        }
      });
    }
  };

  isCartEmpty = () => {
    if (this.state.cart === null) return true;
    return this.state.cart?.photos?.length == 0;
  };

  isSaveCartPopupSeen = () =>
    localStorage.getItem("isUserAskedForEmailToSaveCart")
      ? JSON.parse(
          localStorage.getItem("isUserAskedForEmailToSaveCart") as string
        )
      : false;

  // show cart email popup on clicking add to cart button when user is not signed in, flag is on, paid event, user did not opt for NOT NOW in the past , guest user record null and the cart is empty.
  canShowEmailPopup = () =>
    this.props.customerCartEmailEnabled &&
    !this.isSaveCartPopupSeen() &&
    !this.state.guestUserId &&
    this.isCartEmpty() &&
    !this.props.userSignedIn &&
    !this.props.event.prepaidCustomersEnabled;

  afterAddToCart = () => {
    this.checkDiscountAndGetStatus();
    $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
  };

  private addPhotoToCart(photo: Photo) {
    if (
      this.props.cart &&
      this.props.event.currency !== this.props.cart.currency &&
      this.props.cart.photos.length > 0
    ) {
      // @ts-ignore
      toastr.error(
        `The cart can only contain photos in one currency, currently ${this.props.event.currency}. Clear and try adding again.`
      );
    } else if (
      this.bundleDiscountAvailedFromAnotherEvent(photo) &&
      this.props.cart &&
      this.props.cart.photos.length > 0
    ) {
      // @ts-ignore
      toastr.error(
        `You have an active package discount applied. This feature does not permit photos to be added from multiple events. Please clear cart or remove offer before proceeding.`,
        { timeOut: 9500 }
      );
    } else if (
      this.state.prepaidCustomer &&
      this.props.event.id !== this.state.prepaidCustomer.eventId
    ) {
      // @ts-ignore
      toastr.error(
        "Your photo pack only applies to the event you purchased it for."
      );
    } else {
      ajax
        .post(this.props.addToCartAPI)
        .send({
          photo_id: photo.id,
          activeFilters: this.activeFilters(),
          cart_id: this.state.cart ? this.state.cart.id : null
        })
        .then(res => {
          if (this.canShowEmailPopup()) {
            this.setState(
              {
                cart: res.body.cart
              },
              () => {
                this.afterAddToCart();
                this.setState({ showSaveCartModal: true });
                this.trackCartEmailPopupActions(
                  "event-gallery:cart-email-popup-displayed"
                );
              }
            );
          } else {
            this.setState(
              {
                cart: res.body.cart
              },
              () => {
                // @ts-ignore
                toastr.success("Added to cart", null, { timeOut: 1000 });
                this.afterAddToCart();
              }
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
      if (this.bundleDiscountAvailedFromAnotherEvent(photo)) {
        this.setState(
          {
            cart: {
              ...this.state.cart,
              photoBundleDiscountAvailed: false
            }
          },
          () => this.removePhotoBundleDiscount(false, false)
        );
      }
      // (window.dataLayer || []).push({ event: "addToCart" });
      ahoy.track("albumInteraction", {
        secondary_type: "addToCart",
        photo_id: photo.id,
        album_id: photo.album.id,
        event_id: this.props.event.id
      });
    }
  }

  private findAlbumById(albumId: string) {
    return _.filter(this.state.albums, album => album.signedId === albumId)[0];
  }

  private setSelfieUploaderPopup(value: boolean) {
    this.setState({ selfieUploaderPopup: value });
  }

  private logoutPrepaidCustomer() {
    // @ts-ignore
    toastr.success("Logging you out now...");
    ajax
      .delete(this.props.logoutPrepaidCustomerAPI)
      .send({ cart_id: this.state.cart.id })
      .then(response => {
        this.setState({ prepaidCustomer: null });
      })
      .catch(() =>
        // @ts-ignore
        toastr.failure("Error logging out. Please try again.")
      );
  }

  private updatePhotosCount(photosCount: number) {
    this.setState({ photosCount });
  }

  private updateFilters(newFilters: any, clearFilters: any) {
    this.setState(
      {
        filterSelections: {
          ...(clearFilters
            ? this.state.defaultFilters
            : this.state.filterSelections),
          ...newFilters
        },
        promptRemoveOffer: clearFilters ? false : this.state.promptRemoveOffer
      },
      () => {
        if (this.state.curationMode) {
          this.setSelectAll();
          this.clearMediaIds();
          this.setPhotos([]);
        }
        if (clearFilters || newFilters?.referenceImage === null) {
          deleteCookie("reference_image_for_event");
          if (this.prepaidAuthSelfie) {
            this.unlinkSelfiePrepaidCustomer();
          }
        } else if (this.state.filterSelections.referenceImage != undefined) {
          setCookie(
            "reference_image_for_event",
            `${this.props.event.id}-imageURL-${this.state.filterSelections.referenceImage}`
          );
        }
        if (
          this.props.event.prepaidCustomersEnabled &&
          this.props.event.prepaidCustomersPrimaryAuthField == "selfie" &&
          this.state.filterSelections.referenceImage
        )
          this.handleSelfiePrepaidCustomerCreation(
            this.state.filterSelections.referenceImage
          );
        if (this.props.event.isPrivate) this.togglePrivateEvent();
      }
    );
  }

  private unlinkSelfiePrepaidCustomer = () => {
    ajax
      .delete(
        withCartIdParam(this.props.unlinkPrepaidCustomerAPI, this.state.cart.id)
      )
      .then(res => {
        this.setState({
          cart: res.body.updatedCart,
          prepaidCustomer: res.body.prepaidCustomer,
          selfiePrepaidStatus: "unavailable"
        });
      });
  };

  private handleSelfiePrepaidCustomerCreation = (referenceImageURL: string) => {
    this.setState(
      {
        selfiePrepaidStatus: "unavailable"
      },
      () => {
        ajax
          .post(this.props.createPrepaidCustomerAPI)
          .send({
            reference_image_url: referenceImageURL
          })
          .then(res => {
            this.setState({
              cart: res.body.updatedCart,
              prepaidCustomer: res.body.prepaidCustomer,
              selfiePrepaidStatus: res.body.prepaidCustomer
                ? "available"
                : "availed"
            });
          });
      }
    );
  };

  private togglePrivateEvent() {
    if (this.state.filterSelections.referenceImage) this.unlockPrivateEvent();
    else this.lockPrivateEvent();
  }

  private resetFilter() {
    if (this.state.cart && this.state.cart.photoBundleDiscountAvailed) {
      this.setState({ promptRemoveOffer: true, removeSelfie: true });
    } else if (this.state.albumModeID) {
      this.updateFilters(
        { ...this.state.defaultFilters, albums: [this.state.albums[0].id] },
        false
      );
    } else {
      this.updateFilters(null, true);
    }
  }

  private activeFilters() {
    const filters = this.state.filterSelections;
    const activeFiltersCollection = [];

    if (filters.albums.length > 0) activeFiltersCollection.push("albums");
    if (filters.photographers.length > 0)
      activeFiltersCollection.push("photographers");
    if (filters.photoTime.active) activeFiltersCollection.push("time");
    if (filters.photoText) activeFiltersCollection.push("bib_number");
    if (filters.referenceImage) activeFiltersCollection.push("selfie");
    if (filters.favorite) activeFiltersCollection.push("favorite");

    return activeFiltersCollection;
  }

  private checkDiscountAndGetStatus() {
    if (this.props.event.hasDiscount)
      ajax
        .get(
          withCartIdParam(
            this.props.event.discountStatusPath,
            this.state.cart ? this.state.cart.id : null
          )
        )
        .then(res => {
          this.setState({
            discountMessage: res.body.message,
            cartFullPrice: res.body.cart_full_price,
            cartDiscountedPrice: res.body.cart_discounted_price,
            cartHasDiscount: res.body.cart_has_discount
          });
        });
  }

  private removePhotoFromCart(photo: Photo) {
    const substitutePrepaidPhoto =
      _.includes(this.state.cart.prepaidPhotos, photo.id) &&
      _.head(
        _.difference(this.state.cart.photos, this.state.cart.prepaidPhotos)
      );

    this.setState({
      cart: {
        ...this.state.cart,
        photos: _.without(this.state.cart.photos, photo.id),
        prepaidPhotos: _.concat(
          _.without(this.state.cart.prepaidPhotos, photo.id),
          substitutePrepaidPhoto || []
        )
      }
    });

    ajax
      .delete(this.props.removeFromCartAPI)
      .send({ photo_id: photo.id, cart_id: this.state.cart.id })
      .then(res => {
        // @ts-ignore
        toastr.success("Removed from cart", null, {
          timeOut: 1000
        });
        this.checkDiscountAndGetStatus();
        $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
      })
      .catch(err => {
        console.log(err);
      });
  }

  private async emptyPhotosFromCart() {
    let response = await ajax.delete(this.props.emptyCartAPI).send({
      cart_id: this.state.cart ? this.state.cart.id : null
    });
    if (response.status === 200) {
      this.setState({
        cart: {
          ...this.state.cart,
          photos: []
        }
      });
      $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
    }
  }

  private emptyPhotosFromPrepaidCart() {
    ajax
      .delete(this.props.emptyCartAPI)
      .send({ cart_id: this.state.cart ? this.state.cart.id : null })
      .then(() => {
        this.setState({
          cart: {
            ...this.state.cart,
            photos: []
          },
          clearPrepaidCartPopup: false
        });
        $.getScript(withCartIdParam("/cart_sales_count", this.state.cart.id));
        // @ts-ignore
        toastr.success("Cart has been cleared. You can upload a selfie now.");
      });
  }

  private availPhotoBundleDiscount = async () => {
    if (this.state.cart && this.state.cart.id) {
      await this.emptyPhotosFromCart();
    }
    ajax
      .post(this.props.availPhotoBundleDiscountAPI)
      .send({
        cart_id: this.state.cart ? this.state.cart.id : null,
        event_id: this.props.event.id
      })
      .then(res => {
        this.setState({
          cart: {
            ...this.state.cart,
            ...res.body.cart
          }
        });
      });
  };

  private removePhotoBundleDiscount = (
    availPrepaidOffer: boolean,
    availResetFilter: boolean
  ) => {
    ajax
      .post(this.props.withdrawPhotoBundleDiscountAPI)
      .send({ cart_id: this.state.cart.id })
      .then(res => {
        this.setState({
          cart: {
            ...this.state.cart,
            ...res.body.cart
          },
          promptRemoveOffer: false
        });
        availResetFilter ? this.resetFilter() : null;
        availPrepaidOffer && this.setState({ promptPrepaidAuth: true });
      })
      .catch(err => console.log(err));
  };

  private registerCustomerLead(email: string) {
    if (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email)) {
      ajax
        .post(this.props.registerCustomerLeadAPI)
        .send({ email })
        .then(() => {
          setCookie(
            "customer_email_for_event",
            `${email}-${this.props.event.slug}`
          );
          this.setState({ promptNotifyAuth: false });
          setTimeout(() => this.setState({ notifyBanner: false }), 2000);
          // @ts-ignore
          toastr.success(
            "Thanks, we'll send you an email once the photos are ready."
          );
        })
        .catch(error => {
          // @ts-ignore
          toastr.error(error.response.body.error);
        });
    } else {
      // @ts-ignore
      toastr.error("The email you've entered is invalid.");
    }
  }

  private userOptedNotify() {
    const cookie = getCookie("customer_email_for_event");
    const userEmail = this.props.userEmail;
    const eventSlug = this.props.event.slug;
    if (!cookie) return false;
    const cookieEmail = cookie.split("-")[0];
    const cookieEventSlug = cookie.split("-")[1];
    if (userEmail)
      return cookieEmail === userEmail && cookieEventSlug === eventSlug;
    else return cookieEventSlug === eventSlug;
  }

  private authenticateEvent() {
    const storedEventPassword = getCookie("authenticated_event_password");

    ajax
      .get(this.props.authenticateEventPasswordAPI)
      .query({
        event_password: storedEventPassword
      })
      .then(res => {
        let userAuthenticated =
          res.body.authenticated || !!this.props.bypassPasswordPrompt;
        this.setState(
          {
            userInputPassword: this.props.event.isProtected
              ? userAuthenticated
                ? getCookie("authenticated_event_password") ||
                  this.props.eventPassword
                : ""
              : "",
            authenticated: this.props.event.isProtected
              ? userAuthenticated
              : true
          },
          () => this.setState({ loading: false })
        );
      });
  }

  private loadAlbums() {
    ajax
      .get(this.props.albumsAPI)
      .query({ cartId: this.state.cart ? this.state.cart.id : null })
      .query({ album_id: getAlbumId() })
      .then(
        response => {
          const albums = response.body.albums;
          this.setState({
            albums,
            loading: false,
            //@ts-ignore
            filterSelections: getAlbumId()
              ? {
                  ...this.state.filterSelections,
                  albums: [albums[0].id]
                }
              : this.state.filterSelections,
            filterOptions: {
              ...this.state.filterOptions,
              //@ts-ignore
              albums: _.uniqBy(
                _.map(albums, album => {
                  return {
                    id: album.id,
                    name: album.name,
                    photographerId: album.photographerId,
                    photographerName: album.photographerName
                  };
                }),
                "id"
              ),
              photographers: _.uniqBy(
                _.map(albums, album => {
                  return {
                    id: album.photographerId,
                    name: album.photographerName,
                    albumsCount: _.filter(albums, {
                      photographerId: album.photographerId
                    }).length
                  };
                }),
                "id"
              )
            }
          });
        },
        response => null
      );
  }

  getReferenceImage = () => {
    const cookie = getCookie("reference_image_for_event");
    const [eventId, referenceImage] = cookie ? cookie.split("-imageURL-") : [];
    const photoBundleSelfie =
      this.props.cart &&
      this.props.cart.photoBundleDiscountEventId === this.props.event.id &&
      this.props.cart.photoBundleDiscountAvailed
        ? cookie
        : null;
    const parsedEventId = eventId ? parseInt(eventId) : null;
    if (
      (!cookie && !photoBundleSelfie) ||
      (cookie && parsedEventId != this.props.event.id)
    ) {
      //return null if none of these exist or if cookie exists but the event ID doesn't match
      return null;
    } else if (
      photoBundleSelfie ||
      (parsedEventId === this.props.event.id && referenceImage)
    ) {
      //return reference image if either of the above conditions are true
      return referenceImage;
    }
    return null;
  };

  private navigateToPreviousURL() {
    window.location.assign(document.referrer);
  }

  private unlockProtectedEvent() {
    this.setState(
      {
        authenticated: true
      },
      () =>
        setCookie(
          "authenticated_event_password",
          `${this.state.userInputPassword}`
        )
    );
  }

  private unlockPrivateEvent() {
    this.setState({
      unlockedPrivateEvent: true
    });
  }

  private lockPrivateEvent() {
    this.setState({
      unlockedPrivateEvent: false
    });
  }
}

export default EventGalleryMobile;

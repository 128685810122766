import React, { useCallback } from "react";

const LightBoxNavBtns = ({
  hasPrevImage,
  hasNextImage,
  resetLightBoxImage,
  photoIndex
}: {
  hasPrevImage: boolean;
  hasNextImage: boolean;
  resetLightBoxImage: (index: number) => void;
  photoIndex: number;
}) => {
  const prevImage = useCallback(
    e => {
      if (e) e.preventDefault();
      resetLightBoxImage(photoIndex - 1);
    },
    [photoIndex, resetLightBoxImage]
  );

  const nextImage = useCallback(
    e => {
      if (e) e.preventDefault();
      resetLightBoxImage(photoIndex + 1);
    },
    [photoIndex, resetLightBoxImage]
  );

  const leftArrow = () => {
    return (
      <a
        className="lightbox-nav-btn lb-left-arrow"
        href="#"
        onClick={e => {
          prevImage(e);
        }}
      >
        <span className="fa fa-angle-left" />
      </a>
    );
  };
  const rightArrow = () => {
    return (
      <a
        className="lightbox-nav-btn lb-right-arrow"
        href="#"
        onClick={nextImage}
      >
        <span className="fa fa-angle-right" />
      </a>
    );
  };
  return (
    <>
      {hasPrevImage ? leftArrow() : null}
      {hasNextImage ? rightArrow() : null}
    </>
  );
};

export default LightBoxNavBtns;

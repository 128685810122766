import React, { useState } from "react";
// @ts-ignore
import Webcam from "react-webcam";
import ContrastForegroundUtility from "../../../utils/ContrastForegroundUtility";

const WebcamCapture = ({
  closeWebcam,
  captured,
  primaryColor
}: {
  closeWebcam: () => void;
  captured: (screenshot: string) => void;
  primaryColor: string;
}) => {
  const [webcam, setWebcam] = useState();
  const capture = () => {
    // @ts-ignore
    captured(webcam.getScreenshot());
  };
  const videoConstraints = {
    facingMode: "user"
  };
  return (
    <div>
      <Webcam
        audio={false}
        height={450}
        ref={setWebcam}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        width={500}
      />{" "}
      <div className="d-flex webcam-foo">
        <a className="btn btn-link selfie-up-btn" onClick={closeWebcam}>
          Cancel
        </a>
        <a
          className="btn btn-primary selfie-up-btn"
          style={{
            backgroundColor: primaryColor,
            color: ContrastForegroundUtility.fgColor(primaryColor)
          }}
          onClick={capture}
        >
          Capture photo
        </a>
      </div>
    </div>
  );
};

export default WebcamCapture;

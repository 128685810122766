import React from "react";

const SaveIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66645 0.617676C1.40847 0.617676 0.388672 1.64479 0.388672 2.91179V12.0883C0.388672 13.3553 1.40847 14.3824 2.66645 14.3824H16.3331C17.5911 14.3824 18.6109 13.3553 18.6109 12.0883V5.20591C18.6109 3.93891 17.5911 2.91179 16.3331 2.91179H10.6387L8.36089 0.617676H2.66645ZM10.6387 6.35297C10.6387 5.71947 10.1288 5.20591 9.49978 5.20591C8.87079 5.20591 8.36089 5.71947 8.36089 6.35297V7.50003H7.222C6.59301 7.50003 6.08312 8.01358 6.08312 8.64709C6.08312 9.28059 6.59301 9.79415 7.222 9.79415H8.36089V10.9412C8.36089 11.5747 8.87079 12.0883 9.49978 12.0883C10.1288 12.0883 10.6387 11.5747 10.6387 10.9412V9.79415H11.7776C12.4066 9.79415 12.9164 9.28059 12.9164 8.64709C12.9164 8.01358 12.4066 7.50003 11.7776 7.50003H10.6387V6.35297Z"
        fill={color}
      />
    </svg>
  );
};
export default SaveIcon;

import React, { useContext } from "react";

const BellIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="37"
      height="39"
      viewBox="0 0 37 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8074 31.3814C20.8114 31.3884 20.8149 31.3956 20.819 31.4026C21.8304 33.1544 24.0704 33.7546 25.8222 32.7432C27.5739 31.7318 28.1742 29.4918 27.1628 27.74C27.1587 27.733 27.1543 27.7263 27.1502 27.7194L20.8074 31.3814Z"
        fill={color}
      />
      <path
        d="M31.8173 19.739L26.5415 17.1936C25.8003 15.9099 23.9893 12.7732 23.5209 11.9618C21.4838 8.43343 17.273 6.9431 13.5567 8.20495L12.6266 6.59387C12.1718 5.80636 11.1647 5.53646 10.3771 5.99118C9.58953 6.44591 9.31972 7.45302 9.77441 8.24056L10.7046 9.8517C7.75372 12.4392 6.93898 16.831 8.97603 20.3593C9.7735 21.7406 11.5344 24.7906 11.9966 25.5912L11.5631 31.4328C11.5174 32.0482 11.8157 32.6383 12.3382 32.9664C12.8608 33.2944 13.5219 33.3066 14.0562 32.9981L31.9263 22.6809C32.4606 22.3723 32.7806 21.7937 32.7578 21.1771C32.7349 20.5605 32.373 20.0071 31.8173 19.739Z"
        fill={color}
      />
    </svg>
  );
};
export default BellIcon;

import React from "react";
import _ from "lodash";
import { Photo, Cart } from "../types";
import InfiniteScroll from "react-infinite-scroll-component";
const gssNewLoader = require("../../images/gss-loader.gif");
// @ts-ignore
import PrecacheImg from "../../../../utils/PrecacheImage";
import PhotoGallery from "./PhotoGallery";

interface PhotosProps {
  photos: Photo[];
  cart: Cart;
  addPhotoToCart: (photo: Photo) => void;
  removePhotoFromCart: (photo: Photo) => void;
  loadMorePhotos: () => void;
  isEmbed: boolean;
  hasMorePhotos: boolean;
  isAuthorizedForDirectDownloads: boolean;
  primaryColor?: string;
  userId?: number;
  parentName?: string;
}

class Photos extends React.Component<PhotosProps> {
  constructor(props: PhotosProps) {
    super(props);
  }

  public render() {
    return (
      <div className="dv-album-photos" style={{ overflow: "auto" }}>
        <div className="dv-album-wrap">
          <InfiniteScroll
            dataLength={this.props.photos.length}
            next={this.props.loadMorePhotos}
            hasMore={this.props.hasMorePhotos}
            scrollThreshold={0.7}
            loader={
              <div
                className="egm-loader gss-loader dv-section-footer loading"
                style={{ clear: "both" }}
              >
                <img className="photos-date-img" src={gssNewLoader} />
              </div>
            }
          >
            <PhotoGallery
              cart={this.props.cart}
              photos={this.props.photos}
              addPhotoToCart={this.props.addPhotoToCart}
              removePhotoFromCart={this.props.removePhotoFromCart}
              isEmbed={this.props.isEmbed}
              loadMorePhotos={this.props.loadMorePhotos}
              isAuthorizedForDirectDownloads={
                this.props.isAuthorizedForDirectDownloads
              }
              primaryColor={this.props.primaryColor}
              userId={this.props.userId}
              parentName={this.props.parentName}
            ></PhotoGallery>
          </InfiniteScroll>
        </div>

        <PrecacheImg images={this.imagesToPrefetch()} />
      </div>
    );
  }

  private imagesToPrefetch = () => {
    if (this.props.photos.length > 0) {
      return [
        this.props.photos[0].watermarkedImg,
        this.props.photos[0].zoomImg
      ];
    } else {
      return [];
    }
  };
}

export default Photos;
